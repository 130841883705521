<p-toast position="top-right"></p-toast>
<!-- Toolbar -->
<div class="inset_toolbar-background"></div>
<div class="toolbar" role="banner">
  <img class="toolbar--logo" alt="cfb-weekly-logo" src="/assets/cfb-predict-logo_web.jpg" [ngStyle]="{'cursor': 'pointer'}" />
  <span class="toolbar--name">cfb weekly</span>
  <div class="toolbar--schedule" routerLink='/'><i class="fa fa-calendar"></i></div>
  <div class="toolbar--predict" routerLink='/predict'><i class="fa-solid fa-wand-magic-sparkles"></i></div>
  <div class="toolbar--admin" (click)="adminSignIn()"><i class="fa fa-user-shield"></i></div>
</div>

<div class="content" role="main">

<router-outlet></router-outlet>

<p-dialog header="Admin Sign In" [(visible)]="adminSignInOpen">
  <p id="version__last-prod-push"> v. 231006.0954</p>
  <div>
    <p-password [(ngModel)]="adminPass" toggleMask="true" [strongRegex]="strongRegex"></p-password>
    <button aria-keyshortcuts="enter" (click)="adminPassCheck()" class="check-btn"><span class="fa fa-sign-in-alt"></span></button>
  
  
    <span class="admin-form" [hidden]="!adminRefreshActive">
      <h5 class="admin-form">Input a Year:</h5>
      <p-inputNumber class="admin-form" [(ngModel)]="year" [showButtons]="true" buttonLayout="horizontal" spinnerMode="horizontal" [min]="2015" [max]="2023" [format]="false"
        decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus">
      </p-inputNumber>
      <h5 class="admin-form">Input a Week:</h5>
      <p-inputNumber class="admin-form" [(ngModel)]="week" [showButtons]="true" buttonLayout="horizontal" spinnerMode="horizontal" [min]="0" [max]="20" [format]="false"
      decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus">
    </p-inputNumber>
      <div class="btns">
        <button (click)="updateTeams()" class="teams-btn">Update Teams</button>
        <button (click)="updateGames()" class="games-btn">Update Games</button>
        <button (click)="updatePlays()" class="games-btn">Update Plays</button>
        <button (click)="updateSchedule()" class="games-btn">Update Schedule</button>
      </div>
    </span>
  </div>
</p-dialog>