import { Component, OnInit } from '@angular/core';
import { CFBDataService } from '../services/cfb-data.service';

@Component({
  selector: 'cfbw-tv-schedule',
  templateUrl: './tv-schedule.component.html',
  styleUrls: ['./tv-schedule.component.scss']
})
export class TvScheduleComponent implements OnInit {

  raw = [];
  fullSchedule = [];
  schedule = [];
  dates = [];
  weeks = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15];
  networks = ['ABC', 'NBC', 'CBS', 'FOX', 'ESPN', 'ESPN2', 'ESPNU', 'SECN', 'FS1', 'NBCSN', 'CBSSN', 'LHN']
  week = 0;
  headers = [];
  dateSelected;

  matchupVisible = false;
  matchupData = {};

  currentWeek;
  // isCurrentWeek: boolean;

  constructor(private cfbData: CFBDataService) { }

  ngOnInit(): void {
    this.getLocaleTimes();

    this.cfbData.getCalendar().then(vals => {
      for(let i = 0; i < vals.length; i++)  {
        const today = new Date().toISOString();
        if (vals[i].lastGameStart <= today) {
          console.log(vals);
          this.week = vals[i+1].week;
          this.currentWeek = vals[i+1].week;
          console.log(i, this.week);
        }
      }
      this.changeWeek(this.week);
    });
  }

  formatData(data: any[]) {
    let dates = [];
    for(let i = 0; i < data.length; i++) {
      if (this.networks.find(o => o === data[i].outlet) && (data[i].awayLogo && data[i].homeLogo)) {
        if (!dates.find(d => d.date === data[i].date)) { 
          dates.push({ 
            'date': data[i].date, 
            'networks': [
              { 'network': data[i].outlet, 'games': [data[i]] }
            ]
          });
        } else {
          const dObj = dates.find(d => d.date === data[i].date);
          if (!dObj.networks.find(n => n.network === data[i].outlet)) {
            (data[i].outlet) ? console.log(data[i].date, data[i].outlet) : console.log(data);
            dObj.networks.push({ 'network': data[i].outlet, 'games': [data[i]] })
          } else {
            dObj.networks.find(n => n.network === data[i].outlet).games.push(data[i]);
          }
        }
      }
    };
    this.fullSchedule = dates;
    this.dateSelected = dates[0];

    for(let i = 0; i < this.fullSchedule.length; i++) {
      for(let n = 0; n < this.fullSchedule[i].networks.length; n++) {
        if (this.fullSchedule[i].networks[n].network === 'ABC') {this.fullSchedule[i].networks[n].logo = '/assets/abc.png';}
        if (this.fullSchedule[i].networks[n].network === 'ACCN') {this.fullSchedule[i].networks[n].logo = '/assets/accn.png';}
        if (this.fullSchedule[i].networks[n].network === 'BTN') {this.fullSchedule[i].networks[n].logo = '/assets/btn.png';}
        if (this.fullSchedule[i].networks[n].network === 'CBS') {this.fullSchedule[i].networks[n].logo = '/assets/cbs.png';}
        if (this.fullSchedule[i].networks[n].network === 'CBSSN') {this.fullSchedule[i].networks[n].logo = '/assets/cbssn.jpg';}
        if (this.fullSchedule[i].networks[n].network === 'ESPN') {this.fullSchedule[i].networks[n].logo = '/assets/espn.png';}
        if (this.fullSchedule[i].networks[n].network === 'ESPN2') {this.fullSchedule[i].networks[n].logo = '/assets/espn2.png';}
        if (this.fullSchedule[i].networks[n].network === 'ESPNU') {this.fullSchedule[i].networks[n].logo = '/assets/espnu.png';}
        if (this.fullSchedule[i].networks[n].network === 'FOX') {this.fullSchedule[i].networks[n].logo = '/assets/fox.png';}
        if (this.fullSchedule[i].networks[n].network === 'FS1') {this.fullSchedule[i].networks[n].logo = '/assets/fs1.jpg';}
        if (this.fullSchedule[i].networks[n].network === 'SECN') {this.fullSchedule[i].networks[n].logo = '/assets/secn.png';}
        if (this.fullSchedule[i].networks[n].network === 'LHN') {this.fullSchedule[i].networks[n].logo = '/assets/lhn.png';}
        if (this.fullSchedule[i].networks[n].network === 'NBC') {this.fullSchedule[i].networks[n].logo = '/assets/nbc.png';}
        if (this.fullSchedule[i].networks[n].network === 'NBCSN') {this.fullSchedule[i].networks[n].logo = '/assets/nbcsn.png';}
        if (this.fullSchedule[i].networks[n].network === 'PAC12') {this.fullSchedule[i].networks[n].logo = '/assets/pac12.png';}
        
        this.fullSchedule[i].networks[n].games.sort((a,b) => new Date(a.startTime).valueOf() - new Date(b.startTime).valueOf());
        this.fullSchedule[i].networks[n].games.forEach(game => {
          game.start = new Date(game.startTime).toLocaleTimeString();
          game.end = new Date((new Date(game.startTime).getTime() + (3*60*60*1000))).toLocaleTimeString();
          // game.timestartID = game.startTime.split('T')[1].split(':')[0] + game.startTime.split('T')[1].split(':')[1];
          game.timestartID = this.getTimestamp(game.startTime);
          game.timeendID = game.end.split(':')[0] + game.end.split(':')[1] + game.end.split(' ')[1];
        });
      }
    }
  }

  changeDate(idx: number) {
    console.log(new Date(this.dateSelected.date));
    this.dateSelected = this.fullSchedule[this.fullSchedule.findIndex(i => i === this.dateSelected)+idx]
  }

  getStyle(game, type = null) {
    const away = game?.awayColors ? game?.awayColors[0] : 'lightgray';
    const awayAlt = game?.awayColors ? game?.awayColors[1] : 'gray';
    const home = game?.homeColors ? game?.homeColors[0] : 'lightgray';
    const homeAlt = game?.homeColors ? game?.homeColors[1] : 'gray';

    if (type && type?.split('-')[0] === 'dropshadow') {
      return (type.split('-')[1] === 'away') ? {'filter': `drop-shadow(2px 0px 2px ${awayAlt})`} : {'filter': `drop-shadow(-2px 0px 2px ${homeAlt})`};
    } else {
      return {
        'background': `linear-gradient(122deg, ${away}, ${away} 40%, ${home} 60%, ${home})`,
        'border-style': 'solid',
        'border-width': '2px',
        'border-image': `linear-gradient(to left, ${homeAlt}, ${awayAlt}) 1`
      };
    }
  }

  changeWeek(selection) {
    // this.isCurrentWeek = (selection === this.currentWeek) ? true : false;

    this.cfbData.getSchedule(selection).then(vals => {
      const raw = vals.sort((a,b) => new Date(a.startTime).valueOf() - new Date(b.startTime).valueOf());
      this.formatData(raw);
    });
    this.week = selection;
  }

  getLocaleTimes() {
    const times = [
      '1995-08-25T16:00:00.000Z', '1995-08-25T17:00:00.000Z', '1995-08-25T18:00:00.000Z', '1995-08-25T19:00:00.000Z',
      '1995-08-25T20:00:00.000Z', '1995-08-25T21:00:00.000Z', '1995-08-25T22:00:00.000Z', '1995-08-25T23:00:00.000Z',
      '1995-08-26T00:00:00.000Z', '1995-08-26T01:00:00.000Z', '1995-08-26T02:00:00.000Z', '1995-08-26T03:00:00.000Z',
      '1995-08-25T04:00:00.000Z', '1995-08-25T05:00:00.000Z', '1995-08-25T06:00:00.000Z', '1995-08-25T07:00:00.000Z'
    ]
    for(let i = 0; i < times.length; i++) {
      const date = new Date(times[i]).toLocaleTimeString();
      this.headers.push(`${date.split(':')[0]}${date.split(' ')[1]}`);
    }
  }

  toggleMatchup(selection?) {
    this.matchupData = selection?.matchup ? selection : null;

    console.log(this.matchupData);

    if (this.matchupData) {
      this.matchupVisible = true;
    } else {
      this.matchupVisible = false;
    }
  }

  getTimestamp(iso) {
    console.log(iso);
    let d = new Date(iso);
    let hours = iso.split('T')[1].split(':')[0];
    let minutes = iso.split('T')[1].split(':')[1];

    let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
    let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
    let offset = (((jul - jan) !== 0) ? (((jul - jan) > 0) ? 1 : -1) : 0);
    console.log(offset);

    if (minutes === '45') { minutes = '30'; }
    else if (minutes === '15') { minutes = '00'; }
    
    if (hours === '00' && offset < 0) { return '23' + minutes; }
    else if (hours === '23' && offset > 0) { return '00' + minutes; }
    else if (offset === 0) { return hours + minutes; }
    else {
      return hours + minutes;
      // let convertedHours = (+hours + offset).toString();
      // if (convertedHours.length < 2) { convertedHours = '0' + convertedHours; }
      //   console.log(convertedHours + minutes)
      //   return convertedHours + minutes;
    }
  }

  isDST(iso) {
    let d = new Date(iso);
    let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
    let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
    if (Math.max(jan, jul) !== d.getTimezoneOffset()) {
      return ((jul - jan) > 0) ? 1 : -1;
    } else {
      return 0;
    }
  }

}
