<div id="schedule" class="schedule" *ngIf="!matchupVisible">
    <div class="schedule__toolbar">
        <div class="schedule__toolbar-picker">
            <span>WEEK&nbsp;</span>
            <p-dropdown class="p-component" [options]="weeks" [(ngModel)]="week" (onChange)="changeWeek(week)"
                [style]="{'width': '8rem !important'}" styleClass="schedule__toolbar-picker" [panelStyle]="{'top': '0px', 'background-color': 'lightgray', 'min-width': '4rem'}">
            </p-dropdown>
        </div>
        <div class="schedule__toolbar-left" [hidden]="dateSelected === fullSchedule[0]" (click)="changeDate(-1)"><i class="fa fa-caret-left"></i></div>
        <div class="schedule__toolbar-text">{{dateSelected?.date | date:'EEE, M/d/yyyy' }}</div>
        <div class="schedule__toolbar-right" [hidden]="dateSelected === fullSchedule[fullSchedule.length-1]" (click)="changeDate(1)"><i class="fa fa-caret-right"></i></div>
    </div >
    <div class="schedule__calendar">
        <div class="schedule__calendar-networks">
            <!-- <div class="schedule__calendar-download" (click)="downloadJpeg()"><i class="fa fa-download"></i></div> -->
            <div id="{{network?.network}}" class="schedule__calendar-network" *ngFor="let network of dateSelected?.networks"
                [ngStyle]="{'grid-area': network?.network, 'max-height': '6rem'}">
                <div class="schedule__calendar-network-logo">
                    <img [src]="network?.logo" class="network-logo" id="{{network?.network}}">
                </div>
            </div>
        </div>

        <div class="schedule__calendar-games">
            <div class="schedule__calendar-header">
                <div class="schedule__calendar-header-spacer"></div>
                <div class="schedule__calendar-header-11 span-2">{{headers[0]}}</div>
                <div class="schedule__calendar-header-12 span-2">{{headers[1]}}</div>
                <div class="schedule__calendar-header-13 span-2">{{headers[2]}}</div>
                <div class="schedule__calendar-header-14 span-2">{{headers[3]}}</div>
                <div class="schedule__calendar-header-15 span-2">{{headers[4]}}</div>
                <div class="schedule__calendar-header-16 span-2">{{headers[5]}}</div>
                <div class="schedule__calendar-header-17 span-2">{{headers[6]}}</div>
                <div class="schedule__calendar-header-18 span-2">{{headers[7]}}</div>
                <div class="schedule__calendar-header-19 span-2">{{headers[8]}}</div>
                <div class="schedule__calendar-header-20 span-2">{{headers[9]}}</div>
                <div class="schedule__calendar-header-21 span-2">{{headers[10]}}</div>
                <div class="schedule__calendar-header-22 span-2">{{headers[11]}}</div>
                <div class="schedule__calendar-header-23 span-2">{{headers[12]}}</div>
                <div class="schedule__calendar-header-24 span-2">{{headers[13]}}</div>
                <div class="schedule__calendar-header-01 span-2">{{headers[14]}}</div>
                <div class="schedule__calendar-header-02 span-2">{{headers[15]}}</div>
            </div>
            <div *ngFor="let network of dateSelected?.networks" id="{{network?.network}}" class="schedule__calendar-network-games" [ngStyle]="{'grid-area': network?.network}">
                <div class="schedule__calendar-network-games-1600 cell-border"></div>
                <div class="schedule__calendar-network-games-1630 cell-border"></div>
                <div class="schedule__calendar-network-games-1700 cell-border"></div>
                <div class="schedule__calendar-network-games-1730 cell-border"></div>
                <div class="schedule__calendar-network-games-1800 cell-border"></div>
                <div class="schedule__calendar-network-games-1830 cell-border"></div>
                <div class="schedule__calendar-network-games-1900 cell-border"></div>
                <div class="schedule__calendar-network-games-1930 cell-border"></div>
                <div class="schedule__calendar-network-games-2000 cell-border"></div>
                <div class="schedule__calendar-network-games-2030 cell-border"></div>
                <div class="schedule__calendar-network-games-2100 cell-border"></div>
                <div class="schedule__calendar-network-games-2130 cell-border"></div>
                <div class="schedule__calendar-network-games-2200 cell-border"></div>
                <div class="schedule__calendar-network-games-2230 cell-border"></div>
                <div class="schedule__calendar-network-games-2300 cell-border"></div>
                <div class="schedule__calendar-network-games-2330 cell-border"></div>
                <div class="schedule__calendar-network-games-0000 cell-border"></div>
                <div class="schedule__calendar-network-games-0030 cell-border"></div>
                <div class="schedule__calendar-network-games-0100 cell-border"></div>
                <div class="schedule__calendar-network-games-0130 cell-border"></div>
                <div class="schedule__calendar-network-games-0200 cell-border"></div>
                <div class="schedule__calendar-network-games-0230 cell-border"></div>
                <div class="schedule__calendar-network-games-0300 cell-border"></div>
                <div class="schedule__calendar-network-games-0330 cell-border"></div>
                <div class="schedule__calendar-network-games-0400 cell-border"></div>
                <div class="schedule__calendar-network-games-0430 cell-border"></div>
                <div class="schedule__calendar-network-games-0500 cell-border"></div>
                <div class="schedule__calendar-network-games-0530 cell-border"></div>
                <div class="schedule__calendar-network-games-0600 cell-border"></div>
                <div class="schedule__calendar-network-games-0630 cell-border"></div>
                <div class="schedule__calendar-network-games-0700 cell-border"></div>
                <div class="schedule__calendar-network-games-0730 cell-border"></div>
            </div>
            <div *ngFor="let network of dateSelected?.networks" id="{{network?.network}}" class="schedule__calendar-network-games" [ngStyle]="{'grid-area': network?.network}">
                <div id="{{game.timestartID}}" [ngStyle]="getStyle(game)" class="schedule__calendar-network-games-{{game.timestartID}} game span-4" *ngFor="let game of network.games"
                [ngClass]="{'cursor-pointer': game.matchup}" (click)="toggleMatchup(game)">
                    <div class="game-awayLogo">
                        <img class="team-logo__away" [src]="game?.awayLogo" [ngStyle]="getStyle(game, 'dropshadow-away')">
                    </div>
                    <div class="away-rank">
                        <div class="away-rank__text" *ngIf="game?.awayRank">{{game?.awayRank}}</div>
                    </div>
                    <div class="away-record">
                        <div class="away-record__text" [hidden]="game.awayRecord === '0-0'">({{game?.awayRecord}})</div>
                    </div>

                    <span class="at" style="color:white;">AT</span>

                    <div class="game-homeLogo">
                        <img class="team-logo__home" [src]="game?.homeLogo" [ngStyle]="getStyle(game, 'dropshadow-home')">
                    </div>
                    
                    <div class="home-rank">
                        <div class="home-rank__text" *ngIf="game?.homeRank">{{game?.homeRank}}</div>
                    </div>

                    <div class="home-record">
                        <div class="home-record__text" [hidden]="game.homeRecord === '(0-0)'">({{game?.homeRecord}})</div>
                    </div>

                </div>
            </div>
        </div>
    </div>  
</div>

<cfbw-matchup-preview *ngIf="matchupVisible && matchupData" [matchup]="matchupData" [currentWeek]="currentWeek" (close)="toggleMatchup($event)"></cfbw-matchup-preview>