import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CFBDataService } from '../services/cfb-data.service';
import { Pipe, PipeTransform } from '@angular/core';

@Component({
  selector: 'cfbw-matchup-preview',
  templateUrl: './matchup-preview.component.html',
  styleUrls: ['./matchup-preview.component.scss']
})
export class MatchupPreviewComponent implements OnInit, OnChanges {

  @Input() matchup;
  @Input() currentWeek: number;
  @Output() close = new EventEmitter<any>();

  gameInfo;
  venueInfo;
  historyVisible = true;
  infoVisible = true;
  predictionVisible = true;
  predictionLoading;

  constructor(private cfb: CFBDataService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    if (changes.matchup) {
      if (this.matchup.matchup?.games) {
        this.matchup.matchup.games = this.matchup.matchup.games.reverse();
      }
      this.cfb.getGameInfo(this.matchup.id).then(game => {
        console.log(game);
        this.gameInfo = game;
        this.cfb.getVenueInfo(game.venueId).then(venue => {
          console.log(venue);
          this.venueInfo = venue;
        })
      });
    }
  }

  toggleMatchup() {
    this.close.emit(null);
  }

  getStyle(t?, o?) {
    const away = this.matchup?.awayColors ? this.matchup?.awayColors[0] : 'lightgray';
    const awayAlt = this.matchup?.awayColors ? this.matchup?.awayColors[1] : 'gray';
    const awayPct = this.matchup?.matchup.team1Wins ? (this.matchup?.matchup.team1Wins/(this.matchup?.matchup.team2Wins+this.matchup?.matchup.team1Wins))*100 : 0;
    const home = this.matchup?.homeColors ? this.matchup?.homeColors[0] : 'lightgray';
    const homeAlt = this.matchup?.homeColors ? this.matchup?.homeColors[1] : 'gray';
    const homePct = this.matchup?.matchup.team2Wins ? (this.matchup?.matchup.team2Wins/(this.matchup?.matchup.team2Wins+this.matchup?.matchup.team1Wins))*100 : 0;
    let size;

    if (t && o) {
      if (t.length <= 3 && o.length <= 3) { size = '11vmin'; }
      else if (t.length <= 4 && o.length <= 4) { size = '10vmin'; }
      else { size = '8vmin'; }
      return { 'font-size': size, 'color': (t === this.matchup.homeAbbreviation) ? home : away };
    } else {
      return {
        'background': `linear-gradient(122deg, ${away}, ${away} ${awayPct}%, ${home} ${homePct-awayPct}%, ${home})`,
      };
    }
  }

  getHistoryStyle(game) {
    const color = (game.winner === this.matchup.homeTeam) ? this.matchup?.homeColors[0] : this.matchup?.awayColors[0]
    return {
      'background-color': `${color}`,
    };
  }

  toggleHistory() { this.historyVisible = !this.historyVisible; }
  toggleInfo() { this.infoVisible = !this.infoVisible; }
  togglePrediction() { this.predictionVisible = !this.predictionVisible; }
  isPredictionLoading(event) {
    console.log(event);
    this.predictionLoading = event;
    if (!event) { this.predictionVisible = true; }
  }
}

@Pipe({
  name: 'reverse',
  pure: false
})
export class ReversePipe {
  transform(value) {
    return value.slice().reverse();
  }
}