<div class="loading" *ngIf="loading">
    <div class="typewriter-container">
        <span class="typewriter-left"></span>
        <div class="typewriter"><h1>Calculating........</h1></div>
        <span class="typewriter-right"></span>
    </div>
    <img class="logo t1-logo gif" id="loadingGIF" src="../..//assets/8-bit.gif" alt="loading...">
    <span class="background"></span>
</div>
<div class="container">
    <div class="team-selection-bar">
        <span class="dropdown-cont" style="display: inline-flex;">
            <p-dropdown class="p-component" [options]="teamsList" [(ngModel)]="t1Selected" [filter]="true" filterBy="school"
                optionLabel="school" (onChange)="changeTeamOne()" [showClear]="true" placeholder="Select the 1st Team">
                <ng-template class="p-dropdown" pTemplate="selectedItem">
                    <div *ngIf="t1Selected">
                        <div class="team-name-dropdown">{{ t1Selected.school }}</div>
                    </div>
                </ng-template>
                <ng-template let-team let-index pTemplate="item">
                    <div class="country-item">
                        <img [src]="team?.logos[0]" class="logo-sm" alt=""/>
                        <div class="team-name-dropdown">{{ team.school }}</div>
                    </div>
                </ng-template>
            </p-dropdown>
        </span>
        <h4 class="at">at</h4>
        <span class="dropdown-cont pb" style="display: inline-flex;">
            <p-dropdown class="p-component" [options]="teamsList" [(ngModel)]="t2Selected" [filter]="true" filterBy="school"
                optionLabel="school" (onChange)="changeTeamTwo()"  [showClear]="true"placeholder="Select the 2nd Team">
                <ng-template class="p-dropdown" pTemplate="selectedItem">
                    <div *ngIf="t2Selected">
                        <div class="team-name-dropdown">{{ t2Selected.school }}</div>
                    </div>
                </ng-template>
                <ng-template let-team let-index pTemplate="item">
                    <div class="country-item">
                        <img [src]="team?.logos[0]" class="logo-sm" alt=""/>
                        <div class="team-name-dropdown">{{ team.school }}</div>
                    </div>
                </ng-template>
            </p-dropdown>
        </span>
        <p style="display:inline-flex; padding-right:5px;">Or Neutral?</p>
        <p-inputSwitch [(ngModel)]="isNeutral"></p-inputSwitch>
        <button (click)="fetchClickHandler()" style="padding:5px 20px;">Run it!</button>
    </div>
    <span class="data-section" style="width:100%;" [hidden]="!t2Home?.t2?.total">
        <span>
            <h4 class="title-score">Scoring Summary Prediction</h4>
            <span class="spread" *ngIf="!isNeutral && homeSpread">| Line: {{ homeTeamSpread }} {{ homeSpread | number: '1.0-1' }}, Over/Under: {{ overUnder | number: '1.0-1' }}</span>
            <span class="spread" *ngIf="isNeutral && neutralSpread">| Line: {{ neutralTeamSpread }} {{ neutralSpread | number: '1.0-1' }}, Over/Under: {{ overUnder | number: '1.0-1' }}</span>
        </span>
        <table class="scoring-prediction-table">
            <tr>
                <th class="row-header"></th>
                <th class="row-header">Q1</th>
                <th class="row-header">Q2</th>
                <th class="row-header">Q3</th>
                <th class="row-header">Q4</th>
                <th class="row-header">Total</th>
            </tr>
            <tr class="data-row">
                <td class="row-header">
                    <img class="logo t1-logo" id="teamOneLogo" src="" alt="">
                    <h2 class="team-name t1-color" id="teamOneText" *ngIf="!phoneDevice">{{ t1Selected?.school }}</h2>
                    <h2 class="team-name t1-color" id="teamOneText" *ngIf="phoneDevice">{{ t1Selected?.abbreviation }}</h2>
                </td>
                <td class="td-data" [hidden]="!(!isNeutral && t2Home?.t1)">{{ t2Home?.t1?.q1 | number: '1.0-1' }}</td>
                <td class="td-data" [hidden]="!(!isNeutral && t2Home?.t1)">{{ t2Home?.t1?.q2 | number: '1.0-1' }}</td>
                <td class="td-data" [hidden]="!(!isNeutral && t2Home?.t1)">{{ t2Home?.t1?.q3 | number: '1.0-1' }}</td>
                <td class="td-data" [hidden]="!(!isNeutral && t2Home?.t1)">{{ t2Home?.t1?.q4 | number: '1.0-1' }}</td>

                <td class="td-data" [hidden]="!(isNeutral && t2Home?.t1)">{{ neutral?.t1?.q1 | number: '1.0-1' }}</td>
                <td class="td-data" [hidden]="!(isNeutral && t2Home?.t1)">{{ neutral?.t1?.q2 | number: '1.0-1' }}</td>
                <td class="td-data" [hidden]="!(isNeutral && t2Home?.t1)">{{ neutral?.t1?.q3 | number: '1.0-1' }}</td>
                <td class="td-data" [hidden]="!(isNeutral && t2Home?.t1)">{{ neutral?.t1?.q4 | number: '1.0-1' }}</td>

                <td class="td-data total t1-color" [hidden]="!(!isNeutral && t2Home?.t1)">{{ t2Home?.t1?.total | number: '1.0-1' }}</td>
                <td class="td-data total t1-color" [hidden]="!(isNeutral && neutral?.t1)">{{ neutral?.t1?.total | number: '1.0-1' }}</td>
                <td class="td-data total t1-color" [hidden]="t2Home?.t1 || neutral?.t1"></td>
            </tr>
            <tr class="data-row">
                <td class="row-header">
                    <img class="logo t2-logo" id="teamTwoLogo" src="" alt="">
                    <h2 class="team-name t2-color" id="teamTwoText" *ngIf="!phoneDevice">{{ t2Selected?.school }}</h2>
                    <h2 class="team-name t2-color" id="teamTwoText" *ngIf="phoneDevice">{{ t2Selected?.abbreviation }}</h2>
                </td>
                <td class="td-data" [hidden]="!(!isNeutral && t2Home?.t2)">{{ t2Home?.t2?.q1 | number: '1.0-1' }}</td>
                <td class="td-data" [hidden]="!(!isNeutral && t2Home?.t2)">{{ t2Home?.t2?.q2 | number: '1.0-1' }}</td>
                <td class="td-data" [hidden]="!(!isNeutral && t2Home?.t2)">{{ t2Home?.t2?.q3 | number: '1.0-1' }}</td>
                <td class="td-data" [hidden]="!(!isNeutral && t2Home?.t2)">{{ t2Home?.t2?.q4 | number: '1.0-1' }}</td>
                
                <td class="td-data" [hidden]="!(isNeutral && neutral?.t2)">{{ neutral?.t2?.q1 | number: '1.0-1' }}</td>
                <td class="td-data" [hidden]="!(isNeutral && neutral?.t2)">{{ neutral?.t2?.q2 | number: '1.0-1' }}</td>
                <td class="td-data" [hidden]="!(isNeutral && neutral?.t2)">{{ neutral?.t2?.q3 | number: '1.0-1' }}</td>
                <td class="td-data" [hidden]="!(isNeutral && neutral?.t2)">{{ neutral?.t2?.q4 | number: '1.0-1' }}</td>

                <td class="td-data total t2-color" [hidden]="!(!isNeutral && t2Home?.t2)">{{ t2Home?.t2?.total | number: '1.0-1' }}</td>
                <td class="td-data total t2-color" [hidden]="!(isNeutral && neutral?.t2)">{{ neutral?.t2?.total | number: '1.0-1' }}</td>
                <td class="td-data total t2-color" [hidden]="t2Home?.t2 || neutral?.t2"></td>
            </tr>
        </table>

        <div class="p-tabview">
            <div class="p-tabview-panels">
                <div class="left-side" [hidden]="!t1Selected">
                    <div class="team-header">
                        <img class="logo t1-logo" id="teamOneLogo" src="">
                        <h2 class="team-name t1-color" id="teamOneText" *ngIf="!phoneDevice">{{ t1Selected?.school }} {{ t1Selected?.mascot }}</h2>
                        <h2 class="team-name t1-color" id="teamOneText" *ngIf="phoneDevice">{{ t1Selected?.abbreviation }} {{ t1Selected?.mascot }}</h2>
                    </div>
                    <span class="data-section" style="width:100%;">
                        <table class="scoring-avgs-table">
                            <tr>
                                <th class="row-header" *ngIf="!phoneDevice"></th>
                                <th class="row-header" *ngIf="phoneDevice">Avg Pts</th>
                                <th>Q1</th>
                                <th>Q2</th>
                                <th>Q3</th>
                                <th>Q4</th>
                                <th>Total</th>
                            </tr>
                            <tr class="data-row">
                                <td class="row-header" *ngIf="!phoneDevice">Average Points Scored</td>
                                <td class="row-header small" *ngIf="phoneDevice">Scored</td>
                                <td class="td-data">{{ t1AvgPointsPerQ?.q1 | number: '1.0-1' }}</td>
                                <td class="td-data">{{ t1AvgPointsPerQ?.q2 | number: '1.0-1' }}</td>
                                <td class="td-data">{{ t1AvgPointsPerQ?.q3 | number: '1.0-1' }}</td>
                                <td class="td-data">{{ t1AvgPointsPerQ?.q4 | number: '1.0-1' }}</td>
                                <td class="td-data total t1-color">{{ t1AvgPoints | number: '1.0-1' }}</td>
                            </tr>
                            <tr class="data-row">
                                <td class="row-header" *ngIf="!phoneDevice">Average Points Allowed</td>
                                <td class="row-header small" *ngIf="phoneDevice">Allowed</td>
                                <td class="td-data">{{ t1AvgPointsAllowedPerQ?.q1 | number: '1.0-1' }}</td>
                                <td class="td-data">{{ t1AvgPointsAllowedPerQ?.q2 | number: '1.0-1' }}</td>
                                <td class="td-data">{{ t1AvgPointsAllowedPerQ?.q3 | number: '1.0-1' }}</td>
                                <td class="td-data">{{ t1AvgPointsAllowedPerQ?.q4 | number: '1.0-1' }}</td>
                                <td class="td-data total t1-color">{{ t1AvgPointsAllowed | number: '1.0-1' }}</td>
                            </tr>
                        </table>
                    </span>
                    <span class="data-section" style="width:100%;">
                        <table class="scoring-avgs-table" style="padding: 5px 0px; border-top: none;">
                            <tr>
                                <th class="row-header" *ngIf="!phoneDevice">Opponents Averages</th>
                                <th class="row-header" *ngIf="phoneDevice">Ops Avgs</th>
                                <th>Q1</th>
                                <th>Q2</th>
                                <th>Q3</th>
                                <th>Q4</th>
                                <th>Total</th>
                            </tr>
                            <tr class="data-row">
                                <td class="row-header" *ngIf="!phoneDevice">Average Points Scored</td>
                                <td class="row-header small" *ngIf="phoneDevice">Scored</td>
                                <td class="td-data">{{ t1AvgPtsByOpponentsPerQ?.q1 | number: '1.0-1' }}</td>
                                <td class="td-data">{{ t1AvgPtsByOpponentsPerQ?.q2 | number: '1.0-1' }}</td>
                                <td class="td-data">{{ t1AvgPtsByOpponentsPerQ?.q3 | number: '1.0-1' }}</td>
                                <td class="td-data">{{ t1AvgPtsByOpponentsPerQ?.q4 | number: '1.0-1' }}</td>
                                <td class="td-data total t1-color">{{ t1AvgPtsScoredByOpponents | number: '1.0-1' }}</td>
                            </tr>
                            <tr class="data-row">
                                <td class="row-header" *ngIf="!phoneDevice">Average Points Allowed</td>
                                <td class="row-header small" *ngIf="phoneDevice">Allowed</td>
                                <td class="td-data">{{ t1AvgPtsAllowedByOpponentsPerQ?.q1 | number: '1.0-1' }}</td>
                                <td class="td-data">{{ t1AvgPtsAllowedByOpponentsPerQ?.q2 | number: '1.0-1' }}</td>
                                <td class="td-data">{{ t1AvgPtsAllowedByOpponentsPerQ?.q3 | number: '1.0-1' }}</td>
                                <td class="td-data">{{ t1AvgPtsAllowedByOpponentsPerQ?.q4 | number: '1.0-1' }}</td>
                                <td class="td-data total t1-color">{{ t1AvgPtsAllowedByOpponents | number: '1.0-1' }}</td>
                            </tr>
                        </table>
                    </span>
                    <span class="data-section" style="width:50%; padding-top:25px;">
                        <span class="data-title">Offensive Performance %</span>
                        <span id="t1OffensivePct" class="data-value t1-color">{{ t1OffensivePct | number: '1.0-2' }}</span>
                        <span id="t1OffensivePct" *ngIf="!t1OffensivePct" class="data-value">-</span>
                    </span>
                    <span class="data-section" style="width: 50%; padding-top:25px;">
                        <span class="data-title">Defensive Performance %</span>
                        <span id="t1DefensivePct" class="data-value t1-color">{{ t1DefensivePct | number: '1.0-2' }}</span>
                        <span id="t1DefensivePct" *ngIf="!t1DefensivePct" class="data-value">-</span>
                    </span>
                    <span class="data-section" style="width:100%; padding-top:25px;">
                        <span class="data-title">Performance Figure</span>
                        <span id="t1PerformanceFigure" class="data-value t1-color">{{ t1PerformanceFigure | number: '1.0-2' }}</span>
                        <span id="t1PerformanceFigure" *ngIf="!t1PerformanceFigure" class="data-value">-</span>
                    </span>
                </div>
                <div class="right-side" [hidden]="!t2Selected">
                    <div class="team-header">
                        <img class="logo t2-logo" id="teamTwoLogo" src="">
                        <h2 class="team-name t2-color" id="teamTwoText" *ngIf="!phoneDevice">{{ t2Selected?.school }} {{ t2Selected?.mascot }}</h2>
                        <h2 class="team-name t2-color" id="teamTwoText" *ngIf="phoneDevice">{{ t2Selected?.abbreviation }} {{ t2Selected?.mascot }}</h2>
                    </div>
                    <span class="data-section" style="width:100%;">
                        <table class="scoring-avgs-table">
                            <tr>
                                <th class="row-header" *ngIf="!phoneDevice"></th>
                                <th class="row-header" *ngIf="phoneDevice">Avg Pts</th>
                                <th>Q1</th>
                                <th>Q2</th>
                                <th>Q3</th>
                                <th>Q4</th>
                                <th>Total</th>
                            </tr>
                            <tr class="data-row">
                                <td class="row-header" *ngIf="!phoneDevice">Average Points Scored</td>
                                <td class="row-header small" *ngIf="phoneDevice">Scored</td>
                                <td class="td-data">{{ t2AvgPointsPerQ?.q1 | number: '1.0-1' }}</td>
                                <td class="td-data">{{ t2AvgPointsPerQ?.q2 | number: '1.0-1' }}</td>
                                <td class="td-data">{{ t2AvgPointsPerQ?.q3 | number: '1.0-1' }}</td>
                                <td class="td-data">{{ t2AvgPointsPerQ?.q4 | number: '1.0-1' }}</td>
                                <td class="td-data total t2-color">{{ t2AvgPoints | number: '1.0-1' }}</td>
                            </tr>
                            <tr class="data-row">
                                <td class="row-header" *ngIf="!phoneDevice">Average Points Allowed</td>
                                <td class="row-header small" *ngIf="phoneDevice">Allowed</td>
                                <td class="td-data">{{ t2AvgPointsAllowedPerQ?.q1 | number: '1.0-1' }}</td>
                                <td class="td-data">{{ t2AvgPointsAllowedPerQ?.q2 | number: '1.0-1' }}</td>
                                <td class="td-data">{{ t2AvgPointsAllowedPerQ?.q3 | number: '1.0-1' }}</td>
                                <td class="td-data">{{ t2AvgPointsAllowedPerQ?.q4 | number: '1.0-1' }}</td>
                                <td class="td-data total t2-color">{{ t2AvgPointsAllowed | number: '1.0-1' }}</td>
                            </tr>
                        </table>
                    </span>
                    <span class="data-section" style="width:100%;">
                        <table class="scoring-avgs-table" style="padding: 5px 0px; border-top: none;">
                            <tr>
                                <th class="row-header" *ngIf="!phoneDevice">Opponents Averages</th>
                                <th class="row-header" *ngIf="phoneDevice"> Ops Avgs</th>
                                <th>Q1</th>
                                <th>Q2</th>
                                <th>Q3</th>
                                <th>Q4</th>
                                <th>Total</th>
                            </tr>
                            <tr class="data-row">
                                <td class="row-header" *ngIf="!phoneDevice">Average Points Scored</td>
                                <td class="row-header small" *ngIf="phoneDevice">Scored</td>
                                <td class="td-data">{{ t2AvgPtsByOpponentsPerQ?.q1 | number: '1.0-1' }}</td>
                                <td class="td-data">{{ t2AvgPtsByOpponentsPerQ?.q2 | number: '1.0-1' }}</td>
                                <td class="td-data">{{ t2AvgPtsByOpponentsPerQ?.q3 | number: '1.0-1' }}</td>
                                <td class="td-data">{{ t2AvgPtsByOpponentsPerQ?.q4 | number: '1.0-1' }}</td>
                                <td class="td-data total t2-color">{{ t2AvgPtsScoredByOpponents | number: '1.0-1' }}</td>
                            </tr>
                            <tr class="data-row">
                                <td class="row-header" *ngIf="!phoneDevice">Average Points Allowed</td>
                                <td class="row-header small" *ngIf="phoneDevice">Allowed</td>
                                <td class="td-data">{{ t2AvgPtsAllowedByOpponentsPerQ?.q1 | number: '1.0-1' }}</td>
                                <td class="td-data">{{ t2AvgPtsAllowedByOpponentsPerQ?.q2 | number: '1.0-1' }}</td>
                                <td class="td-data">{{ t2AvgPtsAllowedByOpponentsPerQ?.q3 | number: '1.0-1' }}</td>
                                <td class="td-data">{{ t2AvgPtsAllowedByOpponentsPerQ?.q4 | number: '1.0-1' }}</td>
                                <td class="td-data total t2-color">{{ t2AvgPtsAllowedByOpponents | number: '1.0-1' }}</td>
                            </tr>
                        </table>
                    </span>
                    <span class="data-section" style="width:50%; padding-top:25px;">
                        <span class="data-title">Offensive Performance %</span>
                        <span id="t1OffensivePct" class="data-value t2-color">{{ t2OffensivePct | number: '1.0-2' }}</span>
                        <span id="t2OffensivePct" *ngIf="!t2OffensivePct" class="data-value">-</span>
                    </span>
                    <span class="data-section" style="width:50%; padding-top:25px;">
                        <span class="data-title">Defensive Performance %</span>
                        <span id="t2DefensivePct" class="data-value t2-color">{{ t2DefensivePct | number: '1.0-2' }}</span>
                        <span id="t2DefensivePct" *ngIf="!t2DefensivePct" class="data-value">-</span>
                    </span>
                    <span class="data-section" style="width:100%; padding-top:25px;">
                        <span class="data-title">Performance Figure</span>
                        <span id="t2PerformanceFigure" class="data-value t2-color">{{ t2PerformanceFigure | number: '1.0-2' }}</span>
                        <span id="t2PerformanceFigure" *ngIf="!t2PerformanceFigure" class="data-value">-</span>
                    </span>
                </div>
            </div>
        </div>   
    </span>
</div>
