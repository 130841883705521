<div class="pred-content">
    <div class="pred-content__prediction">
        <span class="pred-content__prediction-spread">Line: <b>{{ homeTeamSpread }} {{ homeSpread | number: '1.0-1' }}</b>, Over/Under: <b>{{ overUnder | number: '1.0-1' }}</b></span>
        <div class="pred-content__prediction-header">
            <p class="pred-content__prediction-header-text q1">Q1</p>
            <p class="pred-content__prediction-header-text q2">Q2</p>
            <p class="pred-content__prediction-header-text q3">Q3</p>
            <p class="pred-content__prediction-header-text q4">Q4</p>
            <p class="pred-content__prediction-header-text t">Total</p>
        </div>
        <div class="pred-content__prediction__row-header aTeam">
            <img class="logo" id="awayTeamLogo" [src]="aTeam?.logos[0]" alt="">
            <h2 class="team-name" id="awayTeamText" [ngStyle]="{'color': aTeam?.color}">{{ aTeam?.school }}</h2>
        </div>
        <div class="pred-content__prediction__row-data aq1">{{ weightedScore?.a?.q1 | number: '1.0-1' }}</div>
        <div class="pred-content__prediction__row-data aq2">{{ weightedScore?.a?.q2 | number: '1.0-1' }}</div>
        <div class="pred-content__prediction__row-data aq3">{{ weightedScore?.a?.q3 | number: '1.0-1' }}</div>
        <div class="pred-content__prediction__row-data aq4">{{ weightedScore?.a?.q4 | number: '1.0-1' }}</div>
        <div class="pred-content__prediction__row-data total at" [ngStyle]="{'color': aTeam?.color}">{{ weightedScore?.a?.total | number: '1.0-1' }}</div>
        <div class="pred-content__prediction__row-header hTeam">
            <img class="logo" id="homeTeamLogo" [src]="hTeam?.logos[0]" alt="">
            <h2 class="team-name" id="homeTeamText" [ngStyle]="{'color': hTeam?.color}">{{ hTeam?.school }}</h2>
        </div>
        <div class="pred-content__prediction__row-data hq1">{{ weightedScore?.h?.q1 | number: '1.0-1' }}</div>
        <div class="pred-content__prediction__row-data hq2">{{ weightedScore?.h?.q2 | number: '1.0-1' }}</div>
        <div class="pred-content__prediction__row-data hq3">{{ weightedScore?.h?.q3 | number: '1.0-1' }}</div>
        <div class="pred-content__prediction__row-data hq4">{{ weightedScore?.h?.q4 | number: '1.0-1' }}</div>
        <div class="pred-content__prediction__row-data total ht" [ngStyle]="{'color': hTeam?.color}">{{ weightedScore?.h?.total | number: '1.0-1' }}</div>
    </div>
    <div class="pred-content__performance">
        <span class="pred-content__performance--alogo"><img id="awayTeamLogo" [src]="aTeam?.logos[0]"></span>
        <span class="pred-content__performance--hlogo"><img id="homeTeamLogo" [src]="hTeam?.logos[0]"></span>
        <span class="pred-content__performance--op-title"><span class="data-title">Performance % (Offense)</span></span>
        <span class="pred-content__performance--dp-title"><span class="data-title">Performance % (Defense)</span></span>
        <span class="pred-content__performance--pf-title"><span class="data-title">Performance Figure</span></span>

        <span id="aOffensivePct" class="pred-content__performance--aop data-value" [ngStyle]="{'color': aTeam?.color}">{{ aOffensivePct | number: '1.0-2' }}</span>
        <span id="aOffensivePct" *ngIf="!aOffensivePct" class="pred-content__performance--aop data-value">-</span>

        <span id="aDefensivePct" class="pred-content__performance--adp data-value" [ngStyle]="{'color': aTeam?.color}">{{ aDefensivePct | number: '1.0-2' }}</span>
        <span id="aDefensivePct" *ngIf="!aDefensivePct" class="pred-content__performance--adp data-value">-</span>
        
        <span id="aPerformanceFigure" class="pred-content__performance--apf data-value" [ngStyle]="{'color': aTeam?.color}">{{ aPerformanceFigure | number: '1.0-2' }}</span>
        <span id="aPerformanceFigure" *ngIf="!aPerformanceFigure" class="pred-content__performance--apf data-value">-</span>
        
        <span id="hOffensivePct" class="pred-content__performance--hop data-value" [ngStyle]="{'color': hTeam?.color}">{{ hOffensivePct | number: '1.0-2' }}</span>
        <span id="hOffensivePct" *ngIf="!hOffensivePct" class="pred-content__performance--hop data-value">-</span>
        
        <span id="hDefensivePct" class="pred-content__performance--hdp data-value" [ngStyle]="{'color': hTeam?.color}">{{ hDefensivePct | number: '1.0-2' }}</span>
        <span id="hDefensivePct" *ngIf="!hDefensivePct" class="pred-content__performance--hdp data-value">-</span>
       
        <span id="hPerformanceFigure" class="pred-content__performance--hpf data-value" [ngStyle]="{'color': hTeam?.color}">{{ hPerformanceFigure | number: '1.0-2' }}</span>
        <span id="hPerformanceFigure" *ngIf="!hPerformanceFigure" class="pred-content__performance--hpf data-value">-</span>
    </div>
    <div class="pred-content__awayAvg">
        <!-- <div class="left-side" [hidden]="!aTeam">
            <div class="team-header">
                <img class="logo a-logo" id="awayTeamLogo" [src]="aTeam?.logos[0]">
                <h2 class="team-name a-color" id="awayTeamText" [ngStyle]="{'color': aTeam?.color}">{{ aTeam?.school }} {{ aTeam?.mascot }}</h2>
            </div>
            <span class="data-section" style="width:100%;">
                <table class="scoring-avgs-table">
                    <tr>
                        <th class="row-header">Avg Pts</th>
                        <th>Q1</th>
                        <th>Q2</th>
                        <th>Q3</th>
                        <th>Q4</th>
                        <th>Total</th>
                    </tr>
                    <tr class="data-row">
                        <td class="row-header small">Avg Scored</td>
                        <td class="td-data">{{ aAvgPointsPerQ?.q1 | number: '1.0-1' }}</td>
                        <td class="td-data">{{ aAvgPointsPerQ?.q2 | number: '1.0-1' }}</td>
                        <td class="td-data">{{ aAvgPointsPerQ?.q3 | number: '1.0-1' }}</td>
                        <td class="td-data">{{ aAvgPointsPerQ?.q4 | number: '1.0-1' }}</td>
                        <td class="td-data total a-color">{{ aAvgPoints | number: '1.0-1' }}</td>
                    </tr>
                    <tr class="data-row">
                        <td class="row-header small">Avg Allowed</td>
                        <td class="td-data">{{ aAvgPointsAllowedPerQ?.q1 | number: '1.0-1' }}</td>
                        <td class="td-data">{{ aAvgPointsAllowedPerQ?.q2 | number: '1.0-1' }}</td>
                        <td class="td-data">{{ aAvgPointsAllowedPerQ?.q3 | number: '1.0-1' }}</td>
                        <td class="td-data">{{ aAvgPointsAllowedPerQ?.q4 | number: '1.0-1' }}</td>
                        <td class="td-data total a-color">{{ aAvgPointsAllowed | number: '1.0-1' }}</td>
                    </tr>
                </table>
            </span>
            <span class="data-section" style="width:100%;">
                <table class="scoring-avgs-table" style="padding: 5px 0px; border-top: none;">
                    <tr>
                        <th class="row-header">Ops Avgs</th>
                        <th>Q1</th>
                        <th>Q2</th>
                        <th>Q3</th>
                        <th>Q4</th>
                        <th>Total</th>
                    </tr>
                    <tr class="data-row">
                        <td class="row-header small">Avg Scored</td>
                        <td class="td-data">{{ aAvgPtsByOpponentsPerQ?.q1 | number: '1.0-1' }}</td>
                        <td class="td-data">{{ aAvgPtsByOpponentsPerQ?.q2 | number: '1.0-1' }}</td>
                        <td class="td-data">{{ aAvgPtsByOpponentsPerQ?.q3 | number: '1.0-1' }}</td>
                        <td class="td-data">{{ aAvgPtsByOpponentsPerQ?.q4 | number: '1.0-1' }}</td>
                        <td class="td-data total a-color">{{ aAvgPtsScoredByOpponents | number: '1.0-1' }}</td>
                    </tr>
                    <tr class="data-row">
                        <td class="row-header small">Avg Allowed</td>
                        <td class="td-data">{{ aAvgPtsAllowedByOpponentsPerQ?.q1 | number: '1.0-1' }}</td>
                        <td class="td-data">{{ aAvgPtsAllowedByOpponentsPerQ?.q2 | number: '1.0-1' }}</td>
                        <td class="td-data">{{ aAvgPtsAllowedByOpponentsPerQ?.q3 | number: '1.0-1' }}</td>
                        <td class="td-data">{{ aAvgPtsAllowedByOpponentsPerQ?.q4 | number: '1.0-1' }}</td>
                        <td class="td-data total a-color">{{ aAvgPtsAllowedByOpponents | number: '1.0-1' }}</td>
                    </tr>
                </table>
            </span>
        </div> -->
    </div>
    <div class="pred-content__homeAvg">
        <!-- <div class="right-side" [hidden]="!hTeam">
            <div class="team-header">
                <img class="logo h-logo" id="homeTeamLogo" [src]="hTeam?.logos[0]">
                <h2 class="team-name h-color" id="homeTeamText" [ngStyle]="{'color': hTeam?.color}">{{ hTeam?.school }} {{ hTeam?.mascot }}</h2>
            </div>
            <span class="data-section" style="width:100%;">
                <table class="scoring-avgs-table">
                    <tr>
                        <th class="row-header">Avg Pts</th>
                        <th>Q1</th>
                        <th>Q2</th>
                        <th>Q3</th>
                        <th>Q4</th>
                        <th>Total</th>
                    </tr>
                    <tr class="data-row">
                        <td class="row-header small">Avg Scored</td>
                        <td class="td-data">{{ hAvgPointsPerQ?.q1 | number: '1.0-1' }}</td>
                        <td class="td-data">{{ hAvgPointsPerQ?.q2 | number: '1.0-1' }}</td>
                        <td class="td-data">{{ hAvgPointsPerQ?.q3 | number: '1.0-1' }}</td>
                        <td class="td-data">{{ hAvgPointsPerQ?.q4 | number: '1.0-1' }}</td>
                        <td class="td-data total h-color">{{ hAvgPoints | number: '1.0-1' }}</td>
                    </tr>
                    <tr class="data-row">
                        <td class="row-header small">Avg Allowed</td>
                        <td class="td-data">{{ hAvgPointsAllowedPerQ?.q1 | number: '1.0-1' }}</td>
                        <td class="td-data">{{ hAvgPointsAllowedPerQ?.q2 | number: '1.0-1' }}</td>
                        <td class="td-data">{{ hAvgPointsAllowedPerQ?.q3 | number: '1.0-1' }}</td>
                        <td class="td-data">{{ hAvgPointsAllowedPerQ?.q4 | number: '1.0-1' }}</td>
                        <td class="td-data total h-color">{{ hAvgPointsAllowed | number: '1.0-1' }}</td>
                    </tr>
                </table>
            </span>
            <span class="data-section" style="width:100%;">
                <table class="scoring-avgs-table" style="padding: 5px 0px; border-top: none;">
                    <tr>
                        <th class="row-header">Ops Avgs</th>
                        <th>Q1</th>
                        <th>Q2</th>
                        <th>Q3</th>
                        <th>Q4</th>
                        <th>Total</th>
                    </tr>
                    <tr class="data-row">
                        <td class="row-header small">Avg Scored</td>
                        <td class="td-data">{{ hAvgPtsByOpponentsPerQ?.q1 | number: '1.0-1' }}</td>
                        <td class="td-data">{{ hAvgPtsByOpponentsPerQ?.q2 | number: '1.0-1' }}</td>
                        <td class="td-data">{{ hAvgPtsByOpponentsPerQ?.q3 | number: '1.0-1' }}</td>
                        <td class="td-data">{{ hAvgPtsByOpponentsPerQ?.q4 | number: '1.0-1' }}</td>
                        <td class="td-data total h-color">{{ hAvgPtsScoredByOpponents | number: '1.0-1' }}</td>
                    </tr>
                    <tr class="data-row">
                        <td class="row-header small">Avg Allowed</td>
                        <td class="td-data">{{ hAvgPtsAllowedByOpponentsPerQ?.q1 | number: '1.0-1' }}</td>
                        <td class="td-data">{{ hAvgPtsAllowedByOpponentsPerQ?.q2 | number: '1.0-1' }}</td>
                        <td class="td-data">{{ hAvgPtsAllowedByOpponentsPerQ?.q3 | number: '1.0-1' }}</td>
                        <td class="td-data">{{ hAvgPtsAllowedByOpponentsPerQ?.q4 | number: '1.0-1' }}</td>
                        <td class="td-data total h-color">{{ hAvgPtsAllowedByOpponents | number: '1.0-1' }}</td>
                    </tr>
                </table>
            </span>
        </div> -->
    </div>
</div>

<!-- <div class="data-section" style="width:100%;" [hidden]="!weightedScore?.h?.total">
    <span>
        <span class="spread">Line: <b>{{ homeTeamSpread }} {{ homeSpread | number: '1.0-1' }}</b>, Over/Under: <b>{{ overUnder | number: '1.0-1' }}</b></span>
    </span>
    <table class="scoring-prediction-table">
        <tr>
            <th class="row-header"></th>
            <th class="row-header">Q1</th>
            <th class="row-header">Q2</th>
            <th class="row-header">Q3</th>
            <th class="row-header">Q4</th>
            <th class="row-header">Total</th>
        </tr>
        <tr class="data-row">
            <td class="row-header">
                <img class="logo a-logo" id="awayTeamLogo" [src]="aTeam?.logos[0]" alt="">
                <h2 class="team-name a-color" id="awayTeamText" [ngStyle]="{'color': aTeam?.color}">{{ aTeam?.school }}</h2>
            </td>
            <td class="td-data" [hidden]="!weightedScore?.a">{{ weightedScore?.a?.q1 | number: '1.0-1' }}</td>
            <td class="td-data" [hidden]="!weightedScore?.a">{{ weightedScore?.a?.q2 | number: '1.0-1' }}</td>
            <td class="td-data" [hidden]="!weightedScore?.a">{{ weightedScore?.a?.q3 | number: '1.0-1' }}</td>
            <td class="td-data" [hidden]="!weightedScore?.a">{{ weightedScore?.a?.q4 | number: '1.0-1' }}</td>

            <td class="td-data total a-color" [hidden]="!weightedScore?.a">{{ weightedScore?.a?.total | number: '1.0-1' }}</td>
            <td class="td-data total a-color" [hidden]="weightedScore?.a"></td>
        </tr>
        <tr class="data-row">
            <td class="row-header">
                <img class="logo h-logo" id="homeTeamLogo" [src]="hTeam?.logos[0]" alt="">
                <h2 class="team-name h-color" id="homeTeamText" [ngStyle]="{'color': hTeam?.color}">{{ hTeam?.school }}</h2>
            </td>
            <td class="td-data" [hidden]="!weightedScore?.h">{{ weightedScore?.h?.q1 | number: '1.0-1' }}</td>
            <td class="td-data" [hidden]="!weightedScore?.h">{{ weightedScore?.h?.q2 | number: '1.0-1' }}</td>
            <td class="td-data" [hidden]="!weightedScore?.h">{{ weightedScore?.h?.q3 | number: '1.0-1' }}</td>
            <td class="td-data" [hidden]="!weightedScore?.h">{{ weightedScore?.h?.q4 | number: '1.0-1' }}</td>

            <td class="td-data total h-color" [hidden]="!weightedScore?.h">{{ weightedScore?.h?.total | number: '1.0-1' }}</td>
            <td class="td-data total h-color" [hidden]="weightedScore?.h"></td>
        </tr>
    </table>

    <div class="p-tabview">
        <div class="p-tabview-panels">
            <div class="away-data">
                <span class="data-section perf-data-logo">
                    <img id="awayTeamLogo" [src]="aTeam?.logos[0]">
                </span>
                <span class="data-section perf-data-off">
                    <span class="data-title">Off Perf %</span>
                    <span id="aOffensivePct" class="data-value a-color" [ngStyle]="{'color': aTeam?.color}">{{ aOffensivePct | number: '1.0-2' }}</span>
                    <span id="aOffensivePct" *ngIf="!aOffensivePct" class="data-value">-</span>
                </span>
                <span class="data-section perf-data-def">
                    <span class="data-title">Def Perf %</span>
                    <span id="aDefensivePct" class="data-value a-color" [ngStyle]="{'color': aTeam?.color}">{{ aDefensivePct | number: '1.0-2' }}</span>
                    <span id="aDefensivePct" *ngIf="!aDefensivePct" class="data-value">-</span>
                </span>
                <span class="data-section perf-data-perf">
                    <span class="data-title">Perf Figure</span>
                    <span id="aPerformanceFigure" class="data-value a-color" [ngStyle]="{'color': aTeam?.color}">{{ aPerformanceFigure | number: '1.0-2' }}</span>
                    <span id="aPerformanceFigure" *ngIf="!aPerformanceFigure" class="data-value">-</span>
                </span>
            </div>
            
            <div class="home-data">
                <span class="data-section perf-data-logo">
                    <img id="homeTeamLogo" [src]="hTeam?.logos[0]">
                </span>
                <span class="data-section perf-data-off">
                    <span class="data-title">Off Perf %</span>
                    <span id="aOffensivePct" class="data-value h-color" [ngStyle]="{'color': hTeam?.color}">{{ hOffensivePct | number: '1.0-2' }}</span>
                    <span id="hOffensivePct" *ngIf="!hOffensivePct" class="data-value">-</span>
                </span>
                <span class="data-section perf-data-def">
                    <span class="data-title">Def Perf %</span>
                    <span id="hDefensivePct" class="data-value h-color" [ngStyle]="{'color': hTeam?.color}">{{ hDefensivePct | number: '1.0-2' }}</span>
                    <span id="hDefensivePct" *ngIf="!hDefensivePct" class="data-value">-</span>
                </span>
                <span class="data-section perf-data-perf">
                    <span class="data-title">Perf Figure</span>
                    <span id="hPerformanceFigure" class="data-value h-color" [ngStyle]="{'color': hTeam?.color}">{{ hPerformanceFigure | number: '1.0-2' }}</span>
                    <span id="hPerformanceFigure" *ngIf="!hPerformanceFigure" class="data-value">-</span>
                </span>
            </div>
            <div class="left-side" [hidden]="!aTeam">
                <div class="team-header">
                    <img class="logo a-logo" id="awayTeamLogo" [src]="aTeam?.logos[0]">
                    <h2 class="team-name a-color" id="awayTeamText" [ngStyle]="{'color': aTeam?.color}">{{ aTeam?.school }} {{ aTeam?.mascot }}</h2>
                </div>
                <span class="data-section" style="width:100%;">
                    <table class="scoring-avgs-table">
                        <tr>
                            <th class="row-header">Avg Pts</th>
                            <th>Q1</th>
                            <th>Q2</th>
                            <th>Q3</th>
                            <th>Q4</th>
                            <th>Total</th>
                        </tr>
                        <tr class="data-row">
                            <td class="row-header small">Avg Scored</td>
                            <td class="td-data">{{ aAvgPointsPerQ?.q1 | number: '1.0-1' }}</td>
                            <td class="td-data">{{ aAvgPointsPerQ?.q2 | number: '1.0-1' }}</td>
                            <td class="td-data">{{ aAvgPointsPerQ?.q3 | number: '1.0-1' }}</td>
                            <td class="td-data">{{ aAvgPointsPerQ?.q4 | number: '1.0-1' }}</td>
                            <td class="td-data total a-color">{{ aAvgPoints | number: '1.0-1' }}</td>
                        </tr>
                        <tr class="data-row">
                            <td class="row-header small">Avg Allowed</td>
                            <td class="td-data">{{ aAvgPointsAllowedPerQ?.q1 | number: '1.0-1' }}</td>
                            <td class="td-data">{{ aAvgPointsAllowedPerQ?.q2 | number: '1.0-1' }}</td>
                            <td class="td-data">{{ aAvgPointsAllowedPerQ?.q3 | number: '1.0-1' }}</td>
                            <td class="td-data">{{ aAvgPointsAllowedPerQ?.q4 | number: '1.0-1' }}</td>
                            <td class="td-data total a-color">{{ aAvgPointsAllowed | number: '1.0-1' }}</td>
                        </tr>
                    </table>
                </span>
                <span class="data-section" style="width:100%;">
                    <table class="scoring-avgs-table" style="padding: 5px 0px; border-top: none;">
                        <tr>
                            <th class="row-header">Ops Avgs</th>
                            <th>Q1</th>
                            <th>Q2</th>
                            <th>Q3</th>
                            <th>Q4</th>
                            <th>Total</th>
                        </tr>
                        <tr class="data-row">
                            <td class="row-header small">Avg Scored</td>
                            <td class="td-data">{{ aAvgPtsByOpponentsPerQ?.q1 | number: '1.0-1' }}</td>
                            <td class="td-data">{{ aAvgPtsByOpponentsPerQ?.q2 | number: '1.0-1' }}</td>
                            <td class="td-data">{{ aAvgPtsByOpponentsPerQ?.q3 | number: '1.0-1' }}</td>
                            <td class="td-data">{{ aAvgPtsByOpponentsPerQ?.q4 | number: '1.0-1' }}</td>
                            <td class="td-data total a-color">{{ aAvgPtsScoredByOpponents | number: '1.0-1' }}</td>
                        </tr>
                        <tr class="data-row">
                            <td class="row-header small">Avg Allowed</td>
                            <td class="td-data">{{ aAvgPtsAllowedByOpponentsPerQ?.q1 | number: '1.0-1' }}</td>
                            <td class="td-data">{{ aAvgPtsAllowedByOpponentsPerQ?.q2 | number: '1.0-1' }}</td>
                            <td class="td-data">{{ aAvgPtsAllowedByOpponentsPerQ?.q3 | number: '1.0-1' }}</td>
                            <td class="td-data">{{ aAvgPtsAllowedByOpponentsPerQ?.q4 | number: '1.0-1' }}</td>
                            <td class="td-data total a-color">{{ aAvgPtsAllowedByOpponents | number: '1.0-1' }}</td>
                        </tr>
                    </table>
                </span>
            </div>
            <div class="right-side" [hidden]="!hTeam">
                <div class="team-header">
                    <img class="logo h-logo" id="homeTeamLogo" [src]="hTeam?.logos[0]">
                    <h2 class="team-name h-color" id="homeTeamText" [ngStyle]="{'color': hTeam?.color}">{{ hTeam?.school }} {{ hTeam?.mascot }}</h2>
                </div>
                <span class="data-section" style="width:100%;">
                    <table class="scoring-avgs-table">
                        <tr>
                            <th class="row-header">Avg Pts</th>
                            <th>Q1</th>
                            <th>Q2</th>
                            <th>Q3</th>
                            <th>Q4</th>
                            <th>Total</th>
                        </tr>
                        <tr class="data-row">
                            <td class="row-header small">Avg Scored</td>
                            <td class="td-data">{{ hAvgPointsPerQ?.q1 | number: '1.0-1' }}</td>
                            <td class="td-data">{{ hAvgPointsPerQ?.q2 | number: '1.0-1' }}</td>
                            <td class="td-data">{{ hAvgPointsPerQ?.q3 | number: '1.0-1' }}</td>
                            <td class="td-data">{{ hAvgPointsPerQ?.q4 | number: '1.0-1' }}</td>
                            <td class="td-data total h-color">{{ hAvgPoints | number: '1.0-1' }}</td>
                        </tr>
                        <tr class="data-row">
                            <td class="row-header small">Avg Allowed</td>
                            <td class="td-data">{{ hAvgPointsAllowedPerQ?.q1 | number: '1.0-1' }}</td>
                            <td class="td-data">{{ hAvgPointsAllowedPerQ?.q2 | number: '1.0-1' }}</td>
                            <td class="td-data">{{ hAvgPointsAllowedPerQ?.q3 | number: '1.0-1' }}</td>
                            <td class="td-data">{{ hAvgPointsAllowedPerQ?.q4 | number: '1.0-1' }}</td>
                            <td class="td-data total h-color">{{ hAvgPointsAllowed | number: '1.0-1' }}</td>
                        </tr>
                    </table>
                </span>
                <span class="data-section" style="width:100%;">
                    <table class="scoring-avgs-table" style="padding: 5px 0px; border-top: none;">
                        <tr>
                            <th class="row-header">Ops Avgs</th>
                            <th>Q1</th>
                            <th>Q2</th>
                            <th>Q3</th>
                            <th>Q4</th>
                            <th>Total</th>
                        </tr>
                        <tr class="data-row">
                            <td class="row-header small">Avg Scored</td>
                            <td class="td-data">{{ hAvgPtsByOpponentsPerQ?.q1 | number: '1.0-1' }}</td>
                            <td class="td-data">{{ hAvgPtsByOpponentsPerQ?.q2 | number: '1.0-1' }}</td>
                            <td class="td-data">{{ hAvgPtsByOpponentsPerQ?.q3 | number: '1.0-1' }}</td>
                            <td class="td-data">{{ hAvgPtsByOpponentsPerQ?.q4 | number: '1.0-1' }}</td>
                            <td class="td-data total h-color">{{ hAvgPtsScoredByOpponents | number: '1.0-1' }}</td>
                        </tr>
                        <tr class="data-row">
                            <td class="row-header small">Avg Allowed</td>
                            <td class="td-data">{{ hAvgPtsAllowedByOpponentsPerQ?.q1 | number: '1.0-1' }}</td>
                            <td class="td-data">{{ hAvgPtsAllowedByOpponentsPerQ?.q2 | number: '1.0-1' }}</td>
                            <td class="td-data">{{ hAvgPtsAllowedByOpponentsPerQ?.q3 | number: '1.0-1' }}</td>
                            <td class="td-data">{{ hAvgPtsAllowedByOpponentsPerQ?.q4 | number: '1.0-1' }}</td>
                            <td class="td-data total h-color">{{ hAvgPtsAllowedByOpponents | number: '1.0-1' }}</td>
                        </tr>
                    </table>
                </span>
            </div>
        </div>
    </div>   
</div> -->