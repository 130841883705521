// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBdGIVO-SbdYJlt4Ae3WEWjYBLJrSWUWWg",
    authDomain: "collegefootballpredict.firebaseapp.com",
    databaseURL: "https://collegefootballpredict-default-rtdb.firebaseio.com",
    projectId: "collegefootballpredict",
    storageBucket: "collegefootballpredict.appspot.com",
    messagingSenderId: "56716871780",
    appId: "1:56716871780:web:587779aa3473623db3b771",
    measurementId: "G-FCQGY94DM9"
    // apiKey: "AIzaSyBdGIVO-SbdYJlt4Ae3WEWjYBLJrSWUWWg",
    // authDomain: "collegefootballpredict.com",
    // projectId: "collegefootballpredict",
    // storageBucket: "collegefootballpredict.appspot.com",
    // messagingSenderId: "56716871780",
    // appId: "1:56716871780:web:587779aa3473623db3b771",
    // measurementId: "G-FCQGY94DM9"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
