import { Component, HostListener, OnInit } from '@angular/core';
import { Quarters, QuartersAvg } from '../domain/quarters';
import { TeamInfo } from '../domain/team-info';
import { TeamsPlayed } from '../domain/teams-played';
import { CalcService } from '../services/calc.service';
import { CFBDataService } from '../services/cfb-data.service';
import { logger } from '../utils/logger';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  // SET UP
  phoneDevice = false;
  testing = false;
  year = 2023;
  teamsList: any;
  loading = false;
  // TEAM ONE
  t1Selected: TeamInfo;
  t1AvgPoints: number;
  t1AvgPointsPerQ: QuartersAvg = {q1: 0, q2: 0, q3: 0, q4: 0};
  t1AvgPointsAllowed: number;
  t1AvgPointsAllowedPerQ: QuartersAvg = {q1: 0, q2: 0, q3: 0, q4: 0};
  t1AvgPlays: {total: number, off: number, def: number} = {total: null, off: null, def: null};
  t1OffPPA: number;
  t1DefPPA: number;
  t1TotalPPA: number;
  
  t1Opponents: TeamsPlayed[];
  
  t1AvgPtsScoredByOpponents: number;
  t1AvgPtsByOpponentsPerQ: QuartersAvg = {q1: 0, q2: 0, q3: 0, q4: 0};
  t1AvgPtsAllowedByOpponents: number;
  t1AvgPtsAllowedByOpponentsPerQ: QuartersAvg = {q1: 0, q2: 0, q3: 0, q4: 0};
  t1AvgPlaysByOpponent: {total: number, off: number, def: number} = {total: null, off: null, def: null};
  
  t1OffPlaysPct: number;
  t1DefPlaysPct: number;
  t1PlaysPerfFigure: number;

  t1OffensivePct: number;
  t1DefensivePct: number;
  t1PerformanceFigure: number;

  // TEAM TWO
  t2Selected: TeamInfo;
  t2AvgPoints: number;
  t2AvgPointsPerQ: QuartersAvg = {q1: 0, q2: 0, q3: 0, q4: 0};
  t2AvgPointsAllowed: number;
  t2AvgPointsAllowedPerQ: QuartersAvg = {q1: 0, q2: 0, q3: 0, q4: 0};
  t2AvgPlays: {total: number, off: number, def: number} = {total: null, off: null, def: null};
  t2OffPPA: number;
  t2DefPPA: number;
  t2TotalPPA: number;
  
  t2Opponents: TeamsPlayed[];
  
  t2AvgPtsScoredByOpponents: number;
  t2AvgPtsByOpponentsPerQ: QuartersAvg = {q1: 0, q2: 0, q3: 0, q4: 0};
  t2AvgPtsAllowedByOpponents: number;
  t2AvgPtsAllowedByOpponentsPerQ: QuartersAvg = {q1: 0, q2: 0, q3: 0, q4: 0};
  t2AvgPlaysByOpponent: {total: number, off: number, def: number} = {total: null, off: null, def: null};
  
  t2OffPlaysPct: number;
  t2DefPlaysPct: number;
  t2PlaysPerfFigure: number;

  t2OffensivePct: number;
  t2DefensivePct: number;
  t2PerformanceFigure: number;

  // Predictions
  estimatedPlays: {total: number, off: number, def: number} = {total: null, off: null, def: null};
  t1PredictedScoreByPlays: number;
  t2PredictedScoreByPlays: number;
  t1PredictedScoreByPoints: number;
  t2PredictedScoreByPoints: number;
  homeTeamSpread;
  neutralTeamSpread;
  homeSpread: number;
  neutralSpread: number;
  overUnder: number;
  isNeutral: boolean = false;
  t1BaseScore: number;
  t1BaseScoreQ: QuartersAvg = {q1: 0, q2: 0, q3: 0, q4: 0};
  t2BaseScore: number;
  t2BaseScoreQ: QuartersAvg = {q1: 0, q2: 0, q3: 0, q4: 0};
  t1Home: {
    t1: { q1: number, q2: number, q3: number, q4: number, total: number }, 
    t2: { q1: number, q2: number, q3: number, q4: number, total: number }
  };
  t2Home: {
    t1: { q1: number, q2: number, q3: number, q4: number, total: number }, 
    t2: { q1: number, q2: number, q3: number, q4: number, total: number }
  };
  neutral: {
    t1: { q1: number, q2: number, q3: number, q4: number, total: number },
    t2: { q1: number, q2: number, q3: number, q4: number, total: number }
  };

  constructor(private cfbData: CFBDataService, private calc: CalcService) { }

  ngOnInit(): void {
    this.reset();
    this.cfbData.getListOfTeams().then(obj => this.teamsList = obj);
    this.onResize();
  }

  fetchClickHandler() {
    this.loading = true;
    this.clear();
    if (this.testing && sessionStorage.getItem('t1Selected')) {
      this.getTestData();
    } else if (this.t1Selected && this.t2Selected) {
      this.loading = true;
      this.cfbData.getGameResults(this.t1Selected.school, this.year).then(
        async obj => {
          const returnData = await this.calc.calcTeamPoints(this.t1Selected.school, this.year, obj);
          this.t1AvgPoints = await this.calc.getWeightedAvg(returnData.avgPointsScored, this.year);
          this.t1AvgPointsPerQ.q1 = await this.calc.getWeightedAvg(returnData.avgPointsPerQ, this.year, 'q1');
          this.t1AvgPointsPerQ.q2 = await this.calc.getWeightedAvg(returnData.avgPointsPerQ, this.year, 'q2');
          this.t1AvgPointsPerQ.q3 = await this.calc.getWeightedAvg(returnData.avgPointsPerQ, this.year, 'q3');
          this.t1AvgPointsPerQ.q4 = await this.calc.getWeightedAvg(returnData.avgPointsPerQ, this.year, 'q4');
          this.t1AvgPointsAllowed = await this.calc.getWeightedAvg(returnData.avgPointsAllowed, this.year);
          this.t1AvgPointsAllowedPerQ.q1 = await this.calc.getWeightedAvg(returnData.avgPointsAllowedPerQ, this.year, 'q1');
          this.t1AvgPointsAllowedPerQ.q2 = await this.calc.getWeightedAvg(returnData.avgPointsAllowedPerQ, this.year, 'q2');
          this.t1AvgPointsAllowedPerQ.q3 = await this.calc.getWeightedAvg(returnData.avgPointsAllowedPerQ, this.year, 'q3');
          this.t1AvgPointsAllowedPerQ.q4 = await this.calc.getWeightedAvg(returnData.avgPointsAllowedPerQ, this.year, 'q4');

          this.t1AvgPlays.total = await this.calc.getWeightedAvg(returnData.avgPlays, this.year, 'total');
          this.t1AvgPlays.off = await this.calc.getWeightedAvg(returnData.avgPlays, this.year, 'off');
          this.t1AvgPlays.def = await this.calc.getWeightedAvg(returnData.avgPlays, this.year, 'def');
          this.t1Opponents = returnData.opponents;
          await this.getT1OpponentsData(0);
        }
      ).finally(async () => {
        if (this.t2Selected) {
          this.cfbData.getGameResults(this.t2Selected.school, this.year).then(
            async obj => {
              const returnData = await this.calc.calcTeamPoints(this.t2Selected.school, this.year, obj);
              this.t2AvgPoints = await this.calc.getWeightedAvg(returnData.avgPointsScored, this.year);
              this.t2AvgPointsPerQ.q1 = await this.calc.getWeightedAvg(returnData.avgPointsPerQ, this.year, 'q1');
              this.t2AvgPointsPerQ.q2 = await this.calc.getWeightedAvg(returnData.avgPointsPerQ, this.year, 'q2');
              this.t2AvgPointsPerQ.q3 = await this.calc.getWeightedAvg(returnData.avgPointsPerQ, this.year, 'q3');
              this.t2AvgPointsPerQ.q4 = await this.calc.getWeightedAvg(returnData.avgPointsPerQ, this.year, 'q4');
              
              this.t2AvgPointsAllowed = await this.calc.getWeightedAvg(returnData.avgPointsAllowed, this.year);
              this.t2AvgPointsAllowedPerQ.q1 = await this.calc.getWeightedAvg(returnData.avgPointsAllowedPerQ, this.year, 'q1');
              this.t2AvgPointsAllowedPerQ.q2 = await this.calc.getWeightedAvg(returnData.avgPointsAllowedPerQ, this.year, 'q2');
              this.t2AvgPointsAllowedPerQ.q3 = await this.calc.getWeightedAvg(returnData.avgPointsAllowedPerQ, this.year, 'q3');
              this.t2AvgPointsAllowedPerQ.q4 = await this.calc.getWeightedAvg(returnData.avgPointsAllowedPerQ, this.year, 'q4');
      
              this.t2AvgPlays.total = await this.calc.getWeightedAvg(returnData.avgPlays, this.year, 'total');
              this.t2AvgPlays.off = await this.calc.getWeightedAvg(returnData.avgPlays, this.year, 'off');
              this.t2AvgPlays.def = await this.calc.getWeightedAvg(returnData.avgPlays, this.year, 'def');

              this.t2Opponents = returnData.opponents;
              this.getT2OpponentsData(0);
            }
          );
        }
      });
    }
  }

  async getT1OpponentsData(index) {
    const team = this.t1Opponents[index]?.team;
    const lastIndex = this.t1Opponents.length - 1;
    if (index <= lastIndex) {
      this.cfbData.getGameResults(team, this.year).then(
        obj => {
          this.t1Opponents[index] = this.calc.calcOpponentsPoints(team, this.year, obj);
          this.getT1OpponentsData(index + 1);
        }
      )
    } else {
        this.t1AvgPtsScoredByOpponents = await this.calc.getOpponentsWeightedAvg(this.t1Opponents, 'avgPointsScored', this.year);
        this.t1AvgPtsByOpponentsPerQ.q1 = await this.calc.getOpponentsWeightedAvg(this.t1Opponents, 'avgPointsPerQ', this.year, 'q1');
        this.t1AvgPtsByOpponentsPerQ.q2 = await this.calc.getOpponentsWeightedAvg(this.t1Opponents, 'avgPointsPerQ', this.year, 'q2');
        this.t1AvgPtsByOpponentsPerQ.q3 = await this.calc.getOpponentsWeightedAvg(this.t1Opponents, 'avgPointsPerQ', this.year, 'q3');
        this.t1AvgPtsByOpponentsPerQ.q4 = await this.calc.getOpponentsWeightedAvg(this.t1Opponents, 'avgPointsPerQ', this.year, 'q4');
        
        this.t1AvgPtsAllowedByOpponents = await this.calc.getOpponentsWeightedAvg(this.t1Opponents, 'avgPointsAllowed', this.year);
        this.t1AvgPtsAllowedByOpponentsPerQ.q1 = await this.calc.getOpponentsWeightedAvg(this.t1Opponents, 'avgPointsAllowedPerQ', this.year, 'q1');
        this.t1AvgPtsAllowedByOpponentsPerQ.q2 = await this.calc.getOpponentsWeightedAvg(this.t1Opponents, 'avgPointsAllowedPerQ', this.year, 'q2');
        this.t1AvgPtsAllowedByOpponentsPerQ.q3 = await this.calc.getOpponentsWeightedAvg(this.t1Opponents, 'avgPointsAllowedPerQ', this.year, 'q3');
        this.t1AvgPtsAllowedByOpponentsPerQ.q4 = await this.calc.getOpponentsWeightedAvg(this.t1Opponents, 'avgPointsAllowedPerQ', this.year, 'q4');

        this.t1AvgPlaysByOpponent.total = await this.calc.getOpponentsWeightedAvg(this.t1Opponents, 'avgPlays', this.year, 'total');
        this.t1AvgPlaysByOpponent.off = await this.calc.getOpponentsWeightedAvg(this.t1Opponents, 'avgPlays', this.year, 'off');
        this.t1AvgPlaysByOpponent.def = await this.calc.getOpponentsWeightedAvg(this.t1Opponents, 'avgPlays', this.year, 'def');
    }
  }

  async getT2OpponentsData(index) {
    const team = this.t2Opponents[index]?.team;
    const lastIndex = this.t2Opponents.length - 1;
    if (index <= lastIndex) {
      this.cfbData.getGameResults(team, this.year).then(
        obj => {
          this.t2Opponents[index] = this.calc.calcOpponentsPoints(team, this.year, obj);
          this.getT2OpponentsData(index + 1);
        }
      )
    } else {
      this.t2AvgPtsScoredByOpponents = await this.calc.getOpponentsWeightedAvg(this.t2Opponents, 'avgPointsScored', this.year);
      this.t2AvgPtsByOpponentsPerQ.q1 = await this.calc.getOpponentsWeightedAvg(this.t2Opponents, 'avgPointsPerQ', this.year, 'q1');
      this.t2AvgPtsByOpponentsPerQ.q2 = await this.calc.getOpponentsWeightedAvg(this.t2Opponents, 'avgPointsPerQ', this.year, 'q2');
      this.t2AvgPtsByOpponentsPerQ.q3 = await this.calc.getOpponentsWeightedAvg(this.t2Opponents, 'avgPointsPerQ', this.year, 'q3');
      this.t2AvgPtsByOpponentsPerQ.q4 = await this.calc.getOpponentsWeightedAvg(this.t2Opponents, 'avgPointsPerQ', this.year, 'q4');
      
      this.t2AvgPtsAllowedByOpponents = await this.calc.getOpponentsWeightedAvg(this.t2Opponents, 'avgPointsAllowed', this.year);
      this.t2AvgPtsAllowedByOpponentsPerQ.q1 = await this.calc.getOpponentsWeightedAvg(this.t2Opponents, 'avgPointsAllowedPerQ', this.year, 'q1');
      this.t2AvgPtsAllowedByOpponentsPerQ.q2 = await this.calc.getOpponentsWeightedAvg(this.t2Opponents, 'avgPointsAllowedPerQ', this.year, 'q2');
      this.t2AvgPtsAllowedByOpponentsPerQ.q3 = await this.calc.getOpponentsWeightedAvg(this.t2Opponents, 'avgPointsAllowedPerQ', this.year, 'q3');
      this.t2AvgPtsAllowedByOpponentsPerQ.q4 = await this.calc.getOpponentsWeightedAvg(this.t2Opponents, 'avgPointsAllowedPerQ', this.year, 'q4');

      this.t2AvgPlaysByOpponent.total = await this.calc.getOpponentsWeightedAvg(this.t2Opponents, 'avgPlays', this.year, 'total');
      this.t2AvgPlaysByOpponent.off = await this.calc.getOpponentsWeightedAvg(this.t2Opponents, 'avgPlays', this.year, 'off');
      this.t2AvgPlaysByOpponent.def = await this.calc.getOpponentsWeightedAvg(this.t2Opponents, 'avgPlays', this.year, 'def');
      await this.calcPerfAndPrediction();
    }
  }

  calcPerfAndPrediction() {
    const t1ppa1 = this.t1Selected.ppa.find(i => i.year === this.year);
    const t1ppa2 = this.t1Selected.ppa.find(i => i.year === this.year-1);
    const t2ppa1 = this.t2Selected.ppa.find(i => i.year === this.year);
    const t2ppa2 = this.t2Selected.ppa.find(i => i.year === this.year-1);

    this.t1OffPPA = (t1ppa1?.offPPA && t2ppa1?.offPPA) ? ((t1ppa1.offPPA)+(t1ppa2.offPPA)/2) : t1ppa2.offPPA;
    this.t1DefPPA = (t1ppa1?.defPPA && t2ppa1?.defPPA) ? ((t1ppa1.defPPA)+(t1ppa2.defPPA)/2) : t1ppa2.defPPA;
    this.t2OffPPA = (t2ppa1?.offPPA && t1ppa1?.offPPA) ? ((t2ppa1.offPPA)+(t2ppa2.offPPA)/2) : t2ppa2.offPPA;
    this.t2DefPPA = (t2ppa1?.defPPA && t1ppa1?.defPPA) ? ((t2ppa1.defPPA)+(t2ppa2.defPPA)/2) : t2ppa2.defPPA;

    // AFTER WEEK 4
    // this.t1OffPPA = t1ppa1?.offPPA;
    // this.t1DefPPA = t1ppa1?.defPPA;
    // this.t2OffPPA = t2ppa1.offPPA;
    // this.t2DefPPA = t2ppa1.defPPA;

    console.log('t1ppa 2023', t1ppa1, 't1ppa 2022', t1ppa2);
    console.log('t2ppa 2023', t2ppa1, 't2ppa 2022', t2ppa2);

    if (this.t1AvgPtsAllowedByOpponents && this.t1AvgPtsScoredByOpponents) {
      this.estimatedPlays.total = (this.t1AvgPlays.total+this.t2AvgPlays.total)/2;
      this.estimatedPlays.off = (this.t1AvgPlays.off+this.t2AvgPlays.def)/2;
      this.estimatedPlays.def = (this.t1AvgPlays.def+this.t2AvgPlays.off)/2;
      // this.t1TotalPPA = (+this.t1OffPPA) + (+this.t2DefPPA);
      // this.t2TotalPPA = (+this.t2OffPPA) + (+this.t1DefPPA);
      let t1PPA = (+this.t1OffPPA + +this.t2DefPPA);
      let t2PPA = (+this.t2OffPPA + +this.t1DefPPA);
      const t1defppa = (this.t1DefPPA < 0) ? +this.t1DefPPA : 0;
      t1PPA -= t1defppa;
      const t2defppa = (this.t2DefPPA < 0) ? +this.t2DefPPA : 0;
      t2PPA -= t2defppa;

      console.log('T1PPA |', t1PPA, 'offPPA |', +this.t1OffPPA, 'T2defPPA |', +this.t2DefPPA, 'defPPA |', -t1defppa);
      console.log('T2PPA |', t2PPA, 'offPPA |', +this.t2OffPPA, 'T1defPPA |', +this.t1DefPPA, 'defPPA |', -t2defppa);

      
      this.t1OffensivePct = this.t1AvgPoints / this.t1AvgPtsAllowedByOpponents;
      this.t1DefensivePct = this.t1AvgPointsAllowed / this.t1AvgPtsScoredByOpponents;
      this.t2OffensivePct = this.t2AvgPoints / this.t2AvgPtsAllowedByOpponents;
      this.t2DefensivePct = this.t2AvgPointsAllowed / this.t2AvgPtsScoredByOpponents;

      this.t1PerformanceFigure = (this.t1OffensivePct + this.t2DefensivePct) / 2;
      this.t2PerformanceFigure = (this.t2OffensivePct + this.t1DefensivePct) / 2;

      this.t1OffPlaysPct = this.t1AvgPlays.off / this.t1AvgPlaysByOpponent.def;
      this.t1DefPlaysPct = this.t1AvgPlays.def / this.t1AvgPlaysByOpponent.off;
      this.t2OffPlaysPct = this.t2AvgPlays.off / this.t2AvgPlaysByOpponent.def;
      this.t2DefPlaysPct = this.t2AvgPlays.def / this.t2AvgPlaysByOpponent.off;

      this.t1PlaysPerfFigure = (this.t1OffPlaysPct + this.t2DefPlaysPct) / 2;
      this.t2PlaysPerfFigure = (this.t2OffPlaysPct + this.t1DefPlaysPct) / 2;

      // this.t1OffPlaysPct = (((this.t1AvgPoints/this.t1AvgPlays.off)/(this.t1AvgPtsAllowedByOpponents/this.t1AvgPlaysByOpponent.def)) + (this.t1AvgPoints/this.t1AvgPtsAllowedByOpponents))/2;
      // this.t1DefPlaysPct = (((this.t1AvgPointsAllowed/this.t1AvgPlays.def)/(this.t1AvgPtsScoredByOpponents/this.t1AvgPlaysByOpponent.off)) + (this.t1AvgPointsAllowed/this.t1AvgPtsScoredByOpponents))/2;
      // this.t2OffPlaysPct = (((this.t2AvgPoints/this.t2AvgPlays.off)/(this.t2AvgPtsAllowedByOpponents/this.t2AvgPlaysByOpponent.def)) + (this.t2AvgPoints/this.t2AvgPtsAllowedByOpponents))/2;
      // this.t2DefPlaysPct = (((this.t2AvgPointsAllowed/this.t2AvgPlays.def)/(this.t2AvgPtsScoredByOpponents/this.t2AvgPlaysByOpponent.off)) + (this.t2AvgPointsAllowed / this.t2AvgPtsScoredByOpponents))/2;

      let t1Plays = ((this.estimatedPlays.off*this.t1OffensivePct)+(this.estimatedPlays.def*this.t2DefensivePct))/2;
      let t2Plays = ((this.estimatedPlays.def*this.t2OffensivePct)+(this.estimatedPlays.off*this.t1DefensivePct))/2;
      // const t1Plays = (this.estimatedPlays.off*this.t1PlaysPerfFigure);
      // const t2Plays = (this.estimatedPlays.def*this.t2PlaysPerfFigure);
      // console.log(this.t1OffensivePct, this.t1DefensivePct, t1Plays, ((t1Plays+t2Plays)/2)*((this.t1AvgPoints/this.t1AvgPlays.off)+(this.t2AvgPointsAllowed/this.t2AvgPlays.def))/2);
      // console.log(this.t2OffensivePct, this.t2DefensivePct, t2Plays, ((t1Plays+t2Plays)/2)*((this.t2AvgPoints/this.t2AvgPlays.off)+(this.t1AvgPointsAllowed/this.t1AvgPlays.def))/2);

      console.log(`Play Team1 | ppa: ${t1PPA}, perfFig: ${this.t1PerformanceFigure}, offPct: ${this.t1OffensivePct}, defPct: ${this.t1DefensivePct}`);
      console.log(`Play Team2 | ppa: ${t2PPA}, perfFig: ${this.t2PerformanceFigure}, offPct: ${this.t2OffensivePct}, defPct: ${this.t2DefensivePct}`);
      // console.log((this.estimatedPlays.off*this.t1OffensivePct)+(this.estimatedPlays.def*this.t2DefensivePct), (this.estimatedPlays.def*this.t2OffensivePct)+(this.estimatedPlays.off*this.t1DefensivePct));
      console.log(t1Plays, t2Plays);
      // const t1plays1 = ((t1Plays+t2Plays)/2)*(((this.t1AvgPoints/this.t1AvgPlays.off)+(this.t2AvgPointsAllowed/this.t2AvgPlays.def))/2);
      // const t2plays1 = ((t1Plays+t2Plays)/2)*(((this.t2AvgPoints/this.t2AvgPlays.off)+(this.t1AvgPointsAllowed/this.t1AvgPlays.def))/2);
      // const t1plays2 = ((t1Plays+t2Plays)/2)*(t1PPA);
      // const t2plays2 = ((t1Plays+t2Plays)/2)*(t2PPA);
      const t1plays1 = ((t1Plays))*(((this.t1AvgPoints/this.t1AvgPlays.off)+(this.t2AvgPointsAllowed/this.t2AvgPlays.def))/2);
      const t2plays1 = ((t2Plays))*(((this.t2AvgPoints/this.t2AvgPlays.off)+(this.t1AvgPointsAllowed/this.t1AvgPlays.def))/2);
      const t1plays2 = ((t1Plays))*((t1PPA*this.t1PerformanceFigure)/2);
      const t2plays2 = ((t2Plays))*((t2PPA*this.t2PerformanceFigure)/2);

      // this.t1PredictedScoreByPlays = (((this.estimatedPlays.off*this.t1OffPlaysPct)+(this.estimatedPlays.def*this.t2DefPlaysPct))*this.t1PlaysPerfFigure)*t1PPA;
      // this.t2PredictedScoreByPlays = (((this.estimatedPlays.def*this.t2OffPlaysPct)+(this.estimatedPlays.off*this.t1DefPlaysPct))*this.t2PlaysPerfFigure)*t2PPA;
      console.log('plays1', 't1', t1plays1, 't2', t2plays1);
      console.log('plays2', 't1', t1plays2, 't2', t2plays2);
      this.t1PredictedScoreByPlays = ((t1plays1 + t1plays2)/2);
      this.t2PredictedScoreByPlays = ((t2plays1 + t2plays2)/2);

      // this.t1PredictedScoreByPlays = (((t1Plays+t2Plays)/2)*(((this.t1AvgPoints/this.t1AvgPlays.off)+(this.t2AvgPointsAllowed/this.t2AvgPlays.def))/2) + ((t1Plays+t2Plays)/2)*(t1PPA))/2;
      // this.t2PredictedScoreByPlays = (((t1Plays+t2Plays)/2)*(((this.t2AvgPoints/this.t2AvgPlays.off)+(this.t1AvgPointsAllowed/this.t1AvgPlays.def))/2) + ((t1Plays+t2Plays)/2)*(t2PPA))/2;
      // console.log((t1Plays)*(((this.t1AvgPoints/this.t1AvgPlays.off)+(this.t2AvgPointsAllowed/this.t2AvgPlays.def))/2), t1Plays*t1PPA);
      // console.log((t2Plays)*(((this.t2AvgPoints/this.t2AvgPlays.off)+(this.t1AvgPointsAllowed/this.t1AvgPlays.def))/2), t2Plays*t2PPA);
      // this.t1PredictedScoreByPlays = ((t1Plays+t2Plays)/2)*(t1PPA+t2PPA);
      // this.t2PredictedScoreByPlays = ((t1Plays+t2Plays)/2)*(t2PPA+t1PPA);
      
      this.t1BaseScore = (this.t1AvgPoints + this.t2AvgPointsAllowed) / 2;
      this.t1BaseScoreQ = {
        q1: (this.t1AvgPointsPerQ.q1 + this.t2AvgPointsAllowedPerQ.q1) / 2,
        q2: (this.t1AvgPointsPerQ.q2 + this.t2AvgPointsAllowedPerQ.q2) / 2,
        q3: (this.t1AvgPointsPerQ.q3 + this.t2AvgPointsAllowedPerQ.q3) / 2,
        q4: (this.t1AvgPointsPerQ.q4 + this.t2AvgPointsAllowedPerQ.q4) / 2
      };

      this.t2BaseScore = (this.t2AvgPoints + this.t1AvgPointsAllowed) / 2;
      this.t2BaseScoreQ = {
        q1: (this.t2AvgPointsPerQ.q1 + this.t1AvgPointsAllowedPerQ.q1) / 2,
        q2: (this.t2AvgPointsPerQ.q2 + this.t1AvgPointsAllowedPerQ.q2) / 2,
        q3: (this.t2AvgPointsPerQ.q3 + this.t1AvgPointsAllowedPerQ.q3) / 2,
        q4: (this.t2AvgPointsPerQ.q4 + this.t1AvgPointsAllowedPerQ.q4) / 2
      };

      const t1QPerc = {
        q1: this.t1BaseScoreQ.q1 / this.t1BaseScore, q2: this.t1BaseScoreQ.q2 / this.t1BaseScore,
        q3: this.t1BaseScoreQ.q3 / this.t1BaseScore, q4: this.t1BaseScoreQ.q4 / this.t1BaseScore,
      }
      const t2QPerc = {
        q1: this.t2BaseScoreQ.q1 / this.t2BaseScore, q2: this.t2BaseScoreQ.q2 / this.t2BaseScore,
        q3: this.t2BaseScoreQ.q3 / this.t2BaseScore, q4: this.t2BaseScoreQ.q4 / this.t2BaseScore,
      }

      this.t1PredictedScoreByPoints = (this.t1BaseScore*this.t1PerformanceFigure);
      this.t2PredictedScoreByPoints = (this.t2BaseScore*this.t2PerformanceFigure);

      this.t1Home = {
        t1: {
          q1: (((this.t1BaseScoreQ.q1*this.t1PerformanceFigure)+0.375)+(t1QPerc.q1*this.t1PredictedScoreByPlays)+0.375)/2,
          q2: (((this.t1BaseScoreQ.q2*this.t1PerformanceFigure)+0.375)+(t1QPerc.q2*this.t1PredictedScoreByPlays)+0.375)/2,
          q3: (((this.t1BaseScoreQ.q3*this.t1PerformanceFigure)+0.375)+(t1QPerc.q3*this.t1PredictedScoreByPlays)+0.375)/2,
          q4: (((this.t1BaseScoreQ.q4*this.t1PerformanceFigure)+0.375)+(t1QPerc.q4*this.t1PredictedScoreByPlays)+0.375)/2,
          total: ((this.t1PredictedScoreByPoints+1.5)+(this.t1PredictedScoreByPlays+1.5))/2
        },
        t2: {
          q1: (((this.t2BaseScoreQ.q1*this.t2PerformanceFigure)-0.375)+(t2QPerc.q1*this.t2PredictedScoreByPlays)-0.375)/2,
          q2: (((this.t2BaseScoreQ.q2*this.t2PerformanceFigure)-0.375)+(t2QPerc.q2*this.t2PredictedScoreByPlays)-0.375)/2,
          q3: (((this.t2BaseScoreQ.q3*this.t2PerformanceFigure)-0.375)+(t2QPerc.q3*this.t2PredictedScoreByPlays)-0.375)/2,
          q4: (((this.t2BaseScoreQ.q4*this.t2PerformanceFigure)-0.375)+(t2QPerc.q4*this.t2PredictedScoreByPlays)-0.375)/2,
          total: ((this.t2PredictedScoreByPoints-1.5)+(this.t2PredictedScoreByPlays-1.5))/2
        }
      };
      this.t2Home = {
        t1: {
          q1: (((this.t1BaseScoreQ.q1*this.t1PerformanceFigure)-0.375)+(t1QPerc.q1*this.t1PredictedScoreByPlays)-0.375)/2,
          q2: (((this.t1BaseScoreQ.q2*this.t1PerformanceFigure)-0.375)+(t1QPerc.q2*this.t1PredictedScoreByPlays)-0.375)/2,
          q3: (((this.t1BaseScoreQ.q3*this.t1PerformanceFigure)-0.375)+(t1QPerc.q3*this.t1PredictedScoreByPlays)-0.375)/2,
          q4: (((this.t1BaseScoreQ.q4*this.t1PerformanceFigure)-0.375)+(t1QPerc.q4*this.t1PredictedScoreByPlays)-0.375)/2,
          total: (((this.t1PredictedScoreByPoints-1.5)+(this.t1PredictedScoreByPlays-1.5))/2)
        },
        t2: {
          q1: (((this.t2BaseScoreQ.q1*this.t2PerformanceFigure)+0.375)+(t2QPerc.q1*this.t2PredictedScoreByPlays)+0.375)/2,
          q2: (((this.t2BaseScoreQ.q2*this.t2PerformanceFigure)+0.375)+(t2QPerc.q2*this.t2PredictedScoreByPlays)+0.375)/2,
          q3: (((this.t2BaseScoreQ.q3*this.t2PerformanceFigure)+0.375)+(t2QPerc.q3*this.t2PredictedScoreByPlays)+0.375)/2,
          q4: (((this.t2BaseScoreQ.q4*this.t2PerformanceFigure)+0.375)+(t2QPerc.q4*this.t2PredictedScoreByPlays)+0.375)/2,
          total: (((this.t2PredictedScoreByPoints+1.5)+(this.t2PredictedScoreByPlays+1.5))/2)
        }
      };
      this.neutral = {
        t1: {
          q1: ((this.t1BaseScoreQ.q1*this.t1PerformanceFigure)+(t1QPerc.q1*this.t1PredictedScoreByPlays))/2,
          q2: ((this.t1BaseScoreQ.q2*this.t1PerformanceFigure)+(t1QPerc.q2*this.t1PredictedScoreByPlays))/2,
          q3: ((this.t1BaseScoreQ.q3*this.t1PerformanceFigure)+(t1QPerc.q3*this.t1PredictedScoreByPlays))/2,
          q4: ((this.t1BaseScoreQ.q4*this.t1PerformanceFigure)+(t1QPerc.q4*this.t1PredictedScoreByPlays))/2,
          total: ((this.t1PredictedScoreByPoints+this.t1PredictedScoreByPlays)/2)
        },
        t2: {
          q1: ((this.t2BaseScoreQ.q1*this.t2PerformanceFigure)+(t2QPerc.q1*this.t2PredictedScoreByPlays))/2,
          q2: ((this.t2BaseScoreQ.q2*this.t2PerformanceFigure)+(t2QPerc.q2*this.t2PredictedScoreByPlays))/2,
          q3: ((this.t2BaseScoreQ.q3*this.t2PerformanceFigure)+(t2QPerc.q3*this.t2PredictedScoreByPlays))/2,
          q4: ((this.t2BaseScoreQ.q4*this.t2PerformanceFigure)+(t2QPerc.q4*this.t2PredictedScoreByPlays))/2,
          total: ((this.t2PredictedScoreByPoints+this.t2PredictedScoreByPlays)/2)
        }
      };

      this.overUnder = this.t2Home.t1.total + this.t2Home.t2.total;
      this.homeTeamSpread = (this.t2Home.t1.total > this.t2Home.t2.total) ? this.t1Selected.abbreviation : this.t2Selected.abbreviation;
      this.homeSpread = (this.t2Home.t1.total > this.t2Home.t2.total) ? this.t2Home.t2.total - this.t2Home.t1.total : this.t2Home.t1.total - this.t2Home.t2.total;
      this.neutralSpread = (this.neutral.t1.total > this.neutral.t2.total) ? this.neutral.t2.total - this.neutral.t1.total : this.neutral.t1.total - this.neutral.t2.total;
      this.neutralTeamSpread = (this.neutral.t1.total > this.neutral.t2.total) ? this.t1Selected.abbreviation : this.t2Selected.abbreviation;
      this.loading = false;
      (this.testing) ? this.setTestData() : null;
    } else {
      setTimeout(() => { this.calcPerfAndPrediction(); }, 1000);
    }

    console.log(`${this.t1Selected.school} @ ${this.t2Selected.school}`);
    console.log(`PlaysScore Team1 - Team2 (home) | ${this.t1PredictedScoreByPlays-1.5} - ${this.t2PredictedScoreByPlays+1.5}`);
    console.log(`PointsScore Team1 - Team2 (home) | ${this.t1PredictedScoreByPoints-1.5}-${this.t2PredictedScoreByPoints+1.5}`);
    console.log(`PredictedScore Team1 - Team2 (home) | ${this.t2Home.t1.total}-${this.t2Home.t2.total}`);
    console.log(`PlaysScore Team1 - Team2 (neutral) | ${this.t1PredictedScoreByPlays} - ${this.t2PredictedScoreByPlays}`);
    console.log(`PointsScore Team1 - Team2 (neutral) | ${this.t1PredictedScoreByPoints}-${this.t2PredictedScoreByPoints}`);
    console.log(`PredictedScore Team1 - Team2 (neutral) | ${this.neutral.t1.total}-${this.neutral.t2.total}`);
  }

  changeTeamOne() {
    this.clear();
    for (let i = 0; i < document.getElementsByClassName('t1-color').length; i++) {
      document.getElementsByClassName('t1-color').item(i).setAttribute('style', `color:${this.t1Selected?.color}`);
    }
    for (let i = 0; i < document.getElementsByClassName('t1-logo').length; i++) {
      document.getElementsByClassName('t1-logo').item(i).setAttribute('src', this.t1Selected?.logos[0]);
    }
  }

  changeTeamTwo() {
    this.clear();
    for (let i = 0; i < document.getElementsByClassName('t2-color').length; i++) {
      document.getElementsByClassName('t2-color').item(i).setAttribute('style', `color:${this.t2Selected?.color}`);
    }
    for (let i = 0; i < document.getElementsByClassName('t2-logo').length; i++) {
      document.getElementsByClassName('t2-logo').item(i).setAttribute('src', this.t2Selected?.logos[0]);
    }  }

  reset() {
    this.t1Opponents = [];
    this.t2Opponents = [];
  }

  clear() {
    this.t1AvgPoints = null;
    this.t1AvgPointsAllowed = null;
    this.t1AvgPtsAllowedByOpponents = null;
    this.t1AvgPtsScoredByOpponents = null;
    this.t1OffensivePct = null;
    this.t1DefensivePct = null;
    this.t1PerformanceFigure = null
    this.t2AvgPoints = null;
    this.t2AvgPointsAllowed = null;
    this.t2AvgPtsAllowedByOpponents = null;
    this.t2AvgPtsScoredByOpponents = null;
    this.t2OffensivePct = null;
    this.t2DefensivePct = null;
    this.t2PerformanceFigure = null
    this.t2Home = {t1: null, t2: null};
    this.neutral = {t1: null, t2: null};
    this.reset();
  }

  getTestData() {
    this.t1Selected = JSON.parse(sessionStorage.getItem('t1Selected'));
    this.t2Selected = JSON.parse(sessionStorage.getItem('t2Selected'));
    this.homeTeamSpread = JSON.parse(sessionStorage.getItem('homeTeamSpread'));
    this.neutralTeamSpread = JSON.parse(sessionStorage.getItem('neutralTeamSpread'));
    this.homeSpread = JSON.parse(sessionStorage.getItem('homeSpread'));
    this.neutralSpread = JSON.parse(sessionStorage.getItem('neutralSpread'));
    this.overUnder = JSON.parse(sessionStorage.getItem('overUnder'));
    this.t2Home = JSON.parse(sessionStorage.getItem('t2Home'));
    this.neutral = JSON.parse(sessionStorage.getItem('neutral'));
    this.t1AvgPointsPerQ = JSON.parse(sessionStorage.getItem('t1AvgPointsPerQ'));
    this.t1AvgPoints = JSON.parse(sessionStorage.getItem('t1AvgPoints'));
    this.t1AvgPointsAllowedPerQ = JSON.parse(sessionStorage.getItem('t1AvgPointsAllowedPerQ'));
    this.t1AvgPointsAllowed = JSON.parse(sessionStorage.getItem('t1AvgPointsAllowed'));
    this.t2AvgPointsPerQ = JSON.parse(sessionStorage.getItem('t2AvgPointsPerQ'));
    this.t2AvgPoints = JSON.parse(sessionStorage.getItem('t2AvgPoints'));
    this.t2AvgPointsAllowedPerQ = JSON.parse(sessionStorage.getItem('t2AvgPointsAllowedPerQ'));
    this.t2AvgPointsAllowed = JSON.parse(sessionStorage.getItem('t2AvgPointsAllowed'));
    this.t1AvgPtsByOpponentsPerQ = JSON.parse(sessionStorage.getItem('t1AvgPtsByOpponentsPerQ'));
    this.t1AvgPtsScoredByOpponents = JSON.parse(sessionStorage.getItem('t1AvgPtsByOpponents'));
    this.t1AvgPtsAllowedByOpponentsPerQ = JSON.parse(sessionStorage.getItem('t1AvgPtsAllowedByOpponentsPerQ'));
    this.t1AvgPtsAllowedByOpponents = JSON.parse(sessionStorage.getItem('t1AvgPtsAllowedByOpponents'));
    this.t2AvgPtsByOpponentsPerQ = JSON.parse(sessionStorage.getItem('t2AvgPtsByOpponentsPerQ'));
    this.t2AvgPtsScoredByOpponents = JSON.parse(sessionStorage.getItem('t2AvgPtsByOpponents'));
    this.t2AvgPtsAllowedByOpponentsPerQ = JSON.parse(sessionStorage.getItem('t2AvgPtsAllowedByOpponentsPerQ'));
    this.t2AvgPtsAllowedByOpponents = JSON.parse(sessionStorage.getItem('t2AvgPtsAllowedByOpponents'));
    this.t1OffensivePct = JSON.parse(sessionStorage.getItem('t1OffensivePct'));
    this.t1DefensivePct = JSON.parse(sessionStorage.getItem('t1DefensivePct'));
    this.t1PerformanceFigure = JSON.parse(sessionStorage.getItem('t1PerformanceFigure'));
    this.t2OffensivePct = JSON.parse(sessionStorage.getItem('t2OffensivePct'));
    this.t2DefensivePct = JSON.parse(sessionStorage.getItem('t2DefensivePct'));
    this.t2PerformanceFigure = JSON.parse(sessionStorage.getItem('t2PerformanceFigure'));
    this.loading = false;
  }

  setTestData() {
    sessionStorage.setItem('t1Selected', JSON.stringify(this.t1Selected));
    sessionStorage.setItem('t2Selected', JSON.stringify(this.t2Selected));
    sessionStorage.setItem('homeTeamSpread', JSON.stringify(this.homeTeamSpread));
    sessionStorage.setItem('neutralTeamSpread', JSON.stringify(this.neutralTeamSpread));
    sessionStorage.setItem('homeSpread', JSON.stringify(this.homeSpread));
    sessionStorage.setItem('neutralSpread', JSON.stringify(this.neutralSpread));
    sessionStorage.setItem('overUnder', JSON.stringify(this.overUnder));
    sessionStorage.setItem('t2Home', JSON.stringify(this.t2Home));
    sessionStorage.setItem('neutral', JSON.stringify(this.neutral));
    sessionStorage.setItem('t1AvgPointsPerQ', JSON.stringify(this.t1AvgPointsPerQ));
    sessionStorage.setItem('t1AvgPoints', JSON.stringify(this.t1AvgPoints));
    sessionStorage.setItem('t1AvgPointsAllowedPerQ', JSON.stringify(this.t1AvgPointsAllowedPerQ));
    sessionStorage.setItem('t1AvgPointsAllowed', JSON.stringify(this.t1AvgPointsAllowed));
    sessionStorage.setItem('t2AvgPointsPerQ', JSON.stringify(this.t2AvgPointsPerQ));
    sessionStorage.setItem('t2AvgPoints', JSON.stringify(this.t2AvgPoints));
    sessionStorage.setItem('t2AvgPointsAllowedPerQ', JSON.stringify(this.t2AvgPointsAllowedPerQ));
    sessionStorage.setItem('t2AvgPointsAllowed', JSON.stringify(this.t2AvgPointsAllowed));
    sessionStorage.setItem('t1AvgPtsByOpponentsPerQ', JSON.stringify(this.t1AvgPtsByOpponentsPerQ));
    sessionStorage.setItem('t1AvgPtsByOpponents', JSON.stringify(this.t1AvgPtsScoredByOpponents));
    sessionStorage.setItem('t1AvgPtsAllowedByOpponentsPerQ', JSON.stringify(this.t1AvgPtsAllowedByOpponentsPerQ));
    sessionStorage.setItem('t1AvgPtsAllowedByOpponents', JSON.stringify(this.t1AvgPtsAllowedByOpponents));
    sessionStorage.setItem('t2AvgPtsByOpponentsPerQ', JSON.stringify(this.t2AvgPtsByOpponentsPerQ));
    sessionStorage.setItem('t2AvgPtsByOpponents', JSON.stringify(this.t2AvgPtsScoredByOpponents));
    sessionStorage.setItem('t2AvgPtsAllowedByOpponentsPerQ', JSON.stringify(this.t2AvgPtsAllowedByOpponentsPerQ));
    sessionStorage.setItem('t2AvgPtsAllowedByOpponents', JSON.stringify(this.t2AvgPtsAllowedByOpponents));
    sessionStorage.setItem('t1OffensivePct', JSON.stringify(this.t1OffensivePct));
    sessionStorage.setItem('t1DefensivePct', JSON.stringify(this.t1DefensivePct));
    sessionStorage.setItem('t1PerformanceFigure', JSON.stringify(this.t1PerformanceFigure));
    sessionStorage.setItem('t2OffensivePct', JSON.stringify(this.t2OffensivePct));
    sessionStorage.setItem('t2DefensivePct', JSON.stringify(this.t2DefensivePct));
    sessionStorage.setItem('t2PerformanceFigure', JSON.stringify(this.t2PerformanceFigure));
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    if (window.innerWidth < 578) {
      this.phoneDevice = true;
    } else {
      this.phoneDevice = false;
    }
  }
}
