import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { tap, catchError } from 'rxjs/operators';
import { logger } from '../utils/logger';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CFBDataService {

  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': '**/*',
      'Accept': '**/*',
      // 'Authorization': 'Bearer YmpXZYY77Ujnvt6ObH+5ttfgUx1oLzQPSTf/adUga17ysISZ5PEqxTRNy2qoqbwp'
    })
  };

  // baseURI = 'https://api.collegefootballpredict.com';
  baseURI = 'http://localhost:3000';
  // baseURI = 'https://us-central1-collegefootballpredict.cloudfunctions.net/app';
  endpoint: string;
  

  constructor(private http: HttpClient, public router: Router, private fb: AngularFirestore) { }

  async getGameResults(team: string, year: number = 2023) {
    var data = {
      [year]: [],
      [year-1]: []
      // [year-2]: []
    };
    await this.fb.collection('games', ref => ref.where('homeTeam', '==', team).where('season', '==', year)).get().forEach(doc => doc.docs.forEach(d => data[year].push(d.data())));
    await this.fb.collection('games', ref => ref.where('awayTeam', '==', team).where('season', '==', year)).get().forEach(doc => doc.docs.forEach(d => data[year].push(d.data())));
    data[year] =  await this.filterGames(data[year], year);
    console.log(year, team, data[year].length);
    if (data[year].length < 3) {
      await this.fb.collection('games', ref => ref.where('homeTeam', '==', team).where('season', '==', (year-1)).where('seasonType', '==', 'regular')).get().forEach(doc => doc.docs.forEach(d => data[year-1].push(d.data())));
      await this.fb.collection('games', ref => ref.where('awayTeam', '==', team).where('season', '==', (year-1)).where('seasonType', '==', 'regular')).get().forEach(doc => doc.docs.forEach(d => data[year-1].push(d.data())));
      console.log(year-1, team, data[year-1].length);
    }
    return await data;
  }

  async getGameInfo(id: number) {
    let data;
    await this.fb.collection('games', ref => ref.where('id', '==', id)).get().forEach(doc => doc.docs.forEach(d => data = d.data()));
    return await data;
  }

  async getVenueInfo(id: number) {
    let data;
    await this.fb.collection('venues', ref => ref.where('id', '==', id)).get().forEach(doc => doc.docs.forEach(d => data = d.data()));
    return await data;
  }

  async getTeamInfo(school: string) {
    let data;
    await this.fb.collection('teams-list', ref => ref.where('school', '==', school)).get().forEach(doc => doc.docs.forEach(d => data = d.data()));
    return await data;
  }

  async getListOfTeams() {
    const data = [];
    await this.fb.collection('teams-list', ref => ref.orderBy('school')).get().forEach(doc => doc.docs.forEach(d => data.push(d.data())));
    return await data;
  }

  async getSchedule(week, year = 2023) {
    const data = [];
    await this.fb.collection('schedule', ref => ref.where('week', '==', week).where('season', '==', year)).get().forEach(doc => doc.docs.forEach(d => data.push(d.data())));
    return await data;
  }

  async getCalendar(year = 2023) {
    const data = [];
    await this.fb.collection('calendar').doc(`${year}`).get().forEach(doc => data.push(doc.data()));
    return await data[0].weeks;
  }

  // loadGames(year: number, week: number, plays: boolean = false): Observable<any> {
  //   const url = `https://us-central1-collegefootballpredict.cloudfunctions.net/games?year=${year}`;

  //   return this.http
  //     .post(url, {headers: this.httpOptions.headers})
  //     .pipe(tap(data => logger(data)),
  //     catchError(err => throwError(err.message)));
  // }

  loadGames(year: number, week: number, plays: boolean = false) {
    const url = plays ?
      `${this.baseURI}/update-db/load-new-games?year=${year}&week=${week}&seasonType=regular&plays=Y` :
      `${this.baseURI}/update-db/load-new-games?year=${year}&week=${week}&seasonType=regular`

    return this.http
      .post(url, {headers: this.httpOptions.headers})
      .pipe(tap(data => logger('loadGames: Returning Data.....')))
  }

  loadTeams(year: number) {
    const url = `${this.baseURI}/update-db/load-teams?year=${year}`;

    return this.http
      .post(url, {headers: this.httpOptions.headers})
      .pipe(tap(data => logger('loadTeams: Returning Data.....')))
  }

  loadSchedule(year: number, week: number) {
    const url = `${this.baseURI}/update-db/load-schedule?year=${year}&week=${week}`;
    // const url = `${this.baseURI}/update-db/load-schedule?year=${year}&week=${week}&action=calendar`;

    return this.http
      .post(url, {headers: this.httpOptions.headers})
      .pipe(tap(data => logger('loadTeams: Returning Data.....')))
  }

  filterGames(d, year) {
    const newObj = [];

    d.forEach(game => ((game?.homePoints !== null && game?.awayPoints !== null) && new Date(game.startDate) <= new Date()) ? newObj.push(game) : null);
    return newObj;
  }
}


