import { DropdownModule } from 'primeng/dropdown';
export { DropdownModule } from 'primeng/dropdown';
import {TabViewModule} from 'primeng/tabview';
import {DialogModule} from 'primeng/dialog';
import {PasswordModule} from 'primeng/password';
import {InputNumberModule} from 'primeng/inputnumber';
import {ToastModule} from 'primeng/toast';
import {InputSwitchModule} from 'primeng/inputswitch';

export {InputSwitchModule} from 'primeng/inputswitch';
export {TabViewModule} from 'primeng/tabview';
export {DialogModule} from 'primeng/dialog';
export {PasswordModule} from 'primeng/password';
export {InputNumberModule} from 'primeng/inputnumber';
export {ToastModule} from 'primeng/toast';

export const PRIMENG_MODULES = [
    DropdownModule,
    InputSwitchModule,
    TabViewModule,
    DialogModule,
    PasswordModule,
    InputNumberModule,
    ToastModule
]