<div class="matchup">
    <div class="matchup__toolbar">
        <div class="matchup__toolbar-close" (click)="toggleMatchup()"><i class="fa fa-times"></i></div>
    </div>
    <div class="matchup-content">
        <div class="matchup-content__header">
            <img class="matchup-content__header-alogo" [src]="matchup.awayLogo">
            <p class="matchup-content__header-awins" [ngStyle]="{'color': matchup.awayColors[0]}">{{matchup.matchup.team1Wins}}</p>
            <p class="matchup-content__header-dash1">-</p>
            <p class="matchup-content__header-ties">{{matchup.matchup.ties}}</p>
            <p class="matchup-content__header-dash2">-</p>
            <p class="matchup-content__header-hwins" [ngStyle]="{'color': matchup.homeColors[0]}">{{matchup.matchup.team2Wins}}</p>
            <img class="matchup-content__header-hlogo" [src]="matchup.homeLogo">
            
            <p class="matchup-content__header-aname" [ngStyle]="getStyle(matchup.awayAbbreviation, matchup.homeAbbreviation)">{{matchup.awayAbbreviation}}</p>
            <p class="matchup-content__header-v">vs.</p>
            <p class="matchup-content__header-hname" [ngStyle]="getStyle(matchup.homeAbbreviation, matchup.awayAbbreviation)">{{matchup.homeAbbreviation}}</p>
    
            <span class="matchup-content__header-line" [ngStyle]="getStyle()"></span>
        </div>
        <div class="matchup-content__history" [ngClass]="{ 'content-closed': !historyVisible }">
            <span class="matchup-content__history-title" (click)="toggleHistory()">
                <p class="matchup-content__history-title-text">Matchup History</p>
                <i class="matchup-content__history-title-arrow fa fa-caret-down" *ngIf="historyVisible"></i>
                <i class="matchup-content__history-title-arrow fa fa-minus dash" *ngIf="!historyVisible"></i>
            </span>
            <div class="matchup-content__history-years" *ngIf="historyVisible">
                <div class="matchup-content__history-years-game" *ngFor="let year of matchup.matchup.games" [ngStyle]="getHistoryStyle(year)">
                    <img class="matchup-content__history-years-game-logo" [src]="(matchup.homeTeam === year.winner) ? matchup.homeLogo : matchup.awayLogo">
                    <p class="matchup-content__history-years-game-season">{{year.season}}</p>
                </div>
            </div>
        </div>
        <div class="matchup-content__info" [ngClass]="{ 'content-closed': !infoVisible }">
            <span class="matchup-content__info-title" (click)="toggleInfo()">
                <p class="matchup-content__info-title-text">Game Info</p>
                <i class="matchup-content__info-title-arrow fa fa-caret-down" *ngIf="infoVisible"></i>
                <i class="matchup-content__info-title-arrow fa fa-minus dash" *ngIf="!infoVisible"></i>
            </span>
            <div class="matchup-content__info-content" *ngIf="infoVisible">
                <p class="matchup-content__info-content-text-nw" [ngStyle]="{'grid-area': 'date'}"><b>{{ matchup?.startTime | date:'h:mm aa, LLL. d, yyyy' }}</b></p>
                <p class="matchup-content__info-content-text" [ngStyle]="{'grid-area': 'outlet'}">Outlet: <b>{{ matchup?.outlet }}</b></p>
                <p class="matchup-content__info-content-text-nw" [ngStyle]="{'grid-area': 'stadium'}"><b>{{ venueInfo?.name }}</b></p>
                <p class="matchup-content__info-content-text" [ngStyle]="{'grid-area': 'location'}">{{venueInfo?.city}}, {{venueInfo?.state}}</p>
                <p class="matchup-content__info-content-text" [ngStyle]="{'grid-area': 'capacity'}">Capacity: <b>{{ venueInfo?.capacity | number }}</b></p>
                <p class="matchup-content__info-content-text" [ngStyle]="{'grid-area': 'grass'}">Grass:
                    <i *ngIf="!venueInfo?.grass" class="fa fa-times matchup-content__info-content-grass" [ngStyle]="{'color': 'red'}"></i>
                    <i *ngIf="venueInfo?.grass" class="fa fa-check matchup-content__info-content-grass" [ngStyle]="{'color': 'green'}"></i>
                </p>
            </div>
        </div>
        <div class="matchup-content__prediction" [ngClass]="{ 'content-closed': !predictionVisible }">
            <span class="matchup-content__prediction-title" (click)="togglePrediction()">
                <p class="matchup-content__prediction-title-text">Matchup Prediction</p>
                <img *ngIf="predictionLoading" class="matchup-content__prediction-title-loading" id="loadingGIF" src="../../assets/loading.gif" alt="loading...">
                <i class="matchup-content__prediction-title-arrow fa fa-caret-down" *ngIf="predictionVisible"></i>
                <i class="matchup-content__prediction-title-arrow fa fa-minus dash" *ngIf="!predictionVisible"></i>
            </span>
            <div class="matchup-content__prediction-content" *ngIf="predictionVisible">
                <cfbw-matchup-prediction (loading)="isPredictionLoading($event)" [currentWeek]="currentWeek" [isNeutral]="gameInfo?.neutralSite" [teamInfo]="{'home': gameInfo?.homeTeam, 'away': gameInfo?.awayTeam}"></cfbw-matchup-prediction>
            </div>
        </div>
    </div>
</div>