import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CFBDataService } from './services/cfb-data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'cfb weekly';

  phoneDevice = false;
  adminSignInOpen = false;
  adminRefreshActive = false;
  adminPass: string;
  strongRegex = 'Admin';
  // strongRegex = 'Admin@Co11egeF00tball';

  year = new Date().getFullYear();
  week = 1;

  constructor(private cfbData: CFBDataService, private toast: MessageService, public router: Router) { 
    this.onResize();
  }

  adminSignIn() {
    this.adminSignInOpen = true;
  }
  adminPassCheck() {
    this.adminRefreshActive = (this.adminPass?.match(this.strongRegex)) ? true : false;
  }

  visibleChange(arg) {
    this.adminRefreshActive = !this.adminRefreshActive;
    this.adminPass = null;
  }

  updateTeams() {
    this.cfbData.loadTeams(this.year).toPromise().then(
      () => this.toast.add({severity: 'success', summary: 'Successfully Loaded New Teams'})
    )
  }

  updateGames() {
    this.cfbData.loadGames(this.year, this.week).subscribe(
      value => {
        console.log(value);
        // this.toast.add({severity: 'success', summary: value.message})
      },
      error => {
        console.log(error);
        this.toast.add({severity: 'error', summary: error});
      }
    )
  }
  
  updatePlays() {
    this.cfbData.loadGames(this.year, this.week, true).subscribe(
      () => this.toast.add({severity: 'success', summary: 'Successfully Loaded Plays'})
    )
  }

  updateSchedule() {
    this.cfbData.loadSchedule(this.year, this.week).toPromise().then(
      () => this.toast.add({severity: 'success', summary: 'Successfully Loaded Schedule'}),
      () => this.toast.add({severity: 'error', summary: 'Failed to load Schedule'})
    )
  }


  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    if (window.innerWidth < 578) {
      this.phoneDevice = true;
    } else {
      this.phoneDevice = false;
    }
  }

}
