import { Injectable } from '@angular/core';
import { Quarters, QuartersAvg } from '../domain/quarters';
import { TeamsPlayed } from '../domain/teams-played';

@Injectable({
  providedIn: 'root'
})
export class CalcService {

  multiplier = 1;
  curYearMult = 1;
  prvYearMult = 1;

  constructor() { }

  calcTeamPoints(team, year, obj): {avgPointsScored, avgPointsAllowed, opponents, avgPointsPerQ, avgPointsAllowedPerQ, avgPlays} {
    let opponents = [];   
    let points = { [year]: [], [year-1]: [] };
    let pointsPerQ = { [year]: {q1: [], q2: [], q3: [], q4: []}, [year-1]: {q1: [], q2: [], q3: [], q4: []} };
    let pointsAllowed = { [year]: [], [year-1]: [] };
    let pointsAllowedPerQ = { [year]: {q1: [], q2: [], q3: [], q4: []}, [year-1]: {q1: [], q2: [], q3: [], q4: []} };
    let avgPoints = { [year]: null, [year-1]: null };
    let avgPointsPerQ = { [year]: {q1: null, q2: null, q3: null, q4: null}, [year-1]: {q1: null, q2: null, q3: null, q4: null} };
    let avgPointsAllowed = { [year]: null, [year-1]: null };
    let avgPointsAllowedPerQ = { [year]: {q1: null, q2: null, q3: null, q4: null}, [year-1]: {q1: null, q2: null, q3: null, q4: null} };
    let plays = {[year]: {total: [], off: [], def: []}, [year-1]: {total: [], off: [], def: []}};
    let avgPlays = {[year]: {total: null, off: null, def: null}, [year-1]: {total: null, off: null, def: null}};
    console.log(obj);
    for (let i=year; i>=(year-1); i--) {
      if (obj[i].length > 0) {
        const teamPtsData = this.getTeamPoints(team, obj[i]);
        points[i] = teamPtsData.points;
        pointsPerQ[i] = teamPtsData.pointsPerQ;
        pointsAllowed[i] = teamPtsData.pointsAllowed;
        pointsAllowedPerQ[i] = teamPtsData.pointsAllowedPerQ;
        opponents.push(...teamPtsData.opponents);
        plays[i] = teamPtsData.plays;
      } 
    }

    // if (points[year]?.length === 0) {
    //   this.curYearMult = 0;
    //   this.prvYearMult = 1;
    // } else {
    //   this.curYearMult = .75;
    //   this.prvYearMult = .25;
    // }

    avgPoints = this.getAvg(points, avgPoints, year);
    console.log(team, avgPoints);
    avgPointsPerQ = this.getAvg(pointsPerQ, avgPointsPerQ, year, 'q1');
    avgPointsPerQ = this.getAvg(pointsPerQ, avgPointsPerQ, year, 'q2');
    avgPointsPerQ = this.getAvg(pointsPerQ, avgPointsPerQ, year, 'q3');
    avgPointsPerQ = this.getAvg(pointsPerQ, avgPointsPerQ, year, 'q4');
    avgPlays = this.getAvg(plays, avgPlays, year, 'total');
    avgPlays = this.getAvg(plays, avgPlays, year, 'off');
    avgPlays = this.getAvg(plays, avgPlays, year, 'def');
    avgPointsAllowed = this.getAvg(pointsAllowed, avgPointsAllowed, year);
    avgPointsAllowedPerQ = this.getAvg(pointsAllowedPerQ, avgPointsAllowedPerQ, year, 'q1');
    avgPointsAllowedPerQ = this.getAvg(pointsAllowedPerQ, avgPointsAllowedPerQ, year, 'q2');
    avgPointsAllowedPerQ = this.getAvg(pointsAllowedPerQ, avgPointsAllowedPerQ, year, 'q3');
    avgPointsAllowedPerQ = this.getAvg(pointsAllowedPerQ, avgPointsAllowedPerQ, year, 'q4');
    return {avgPointsScored: avgPoints, avgPointsAllowed: avgPointsAllowed, opponents: opponents, avgPointsPerQ: avgPointsPerQ, avgPointsAllowedPerQ: avgPointsAllowedPerQ, avgPlays: avgPlays};
  }

  calcOpponentsPoints(team, year, obj): {team, avgPointsScored, avgPointsAllowed, avgPointsPerQ, avgPointsAllowedPerQ, avgPlays} {
    let points = { [year]: [], [year-1]: [] };
    let pointsPerQ = { [year]: {q1: [], q2: [], q3: [], q4: []}, [year-1]: {q1: [], q2: [], q3: [], q4: []} };
    let pointsAllowed = { [year]: [], [year-1]: [] };
    let pointsAllowedPerQ = { [year]: {q1: [], q2: [], q3: [], q4: []}, [year-1]: {q1: [], q2: [], q3: [], q4: []} };
    let avgPoints = { [year]: null, [year-1]: null };
    let avgPointsPerQ = { [year]: {q1: null, q2: null, q3: null, q4: null}, [year-1]: {q1: null, q2: null, q3: null, q4: null} };
    let avgPointsAllowed = { [year]: null, [year-1]: null };
    let avgPointsAllowedPerQ = { [year]: {q1: null, q2: null, q3: null, q4: null}, [year-1]: {q1: null, q2: null, q3: null, q4: null} };
    let plays = {[year]: {total: [], off: [], def: []}, [year-1]: {total: [], off: [], def: []}};
    let avgPlays = {[year]: {total: null, off: null, def: null}, [year-1]: {total: null, off: null, def: null}};

    // let points = { [year]: [] };
    // let pointsPerQ = { [year]: {q1: [], q2: [], q3: [], q4: []} };
    // let pointsAllowed = { [year]: [] };
    // let pointsAllowedPerQ = { [year]: {q1: [], q2: [], q3: [], q4: []} };
    // let avgPoints = { [year]: null};
    // let avgPointsPerQ = { [year]: {q1: null, q2: null, q3: null, q4: null} };
    // let avgPointsAllowed = { [year]: null };
    // let avgPointsAllowedPerQ = { [year]: {q1: null, q2: null, q3: null, q4: null}};
    // let plays = {[year]: {total: [], off: [], def: []}};
    // let avgPlays = {[year]: {total: null, off: null, def: null}};
    
      for (let i=year; i>=(year-1); i--) {
        if (obj[i].length > 0) {
          const teamPtsData = this.getTeamPoints(team, obj[i]);
          points[i] = teamPtsData.points;
          pointsPerQ[i] = teamPtsData.pointsPerQ;
          pointsAllowed[i] = teamPtsData.pointsAllowed;
          pointsAllowedPerQ[i] = teamPtsData.pointsAllowedPerQ;
          plays[i] = teamPtsData.plays;
        } 
      }

      // if (points[year]?.length === 0) {
      //   this.curYearMult = 0;
      //   this.prvYearMult = 1;
      // } else {
      //   this.curYearMult = .75;
      //   this.prvYearMult = .25;
      // }
  
      avgPoints = this.getAvg(points, avgPoints, year);
      avgPointsPerQ = this.getAvg(pointsPerQ, avgPointsPerQ, year, 'q1');
      avgPointsPerQ = this.getAvg(pointsPerQ, avgPointsPerQ, year, 'q2');
      avgPointsPerQ = this.getAvg(pointsPerQ, avgPointsPerQ, year, 'q3');
      avgPointsPerQ = this.getAvg(pointsPerQ, avgPointsPerQ, year, 'q4');
      avgPlays = this.getAvg(plays, avgPlays, year, 'total');
      avgPlays = this.getAvg(plays, avgPlays, year, 'off');
      avgPlays = this.getAvg(plays, avgPlays, year, 'def');
      avgPointsAllowed = this.getAvg(pointsAllowed, avgPointsAllowed, year);
      avgPointsAllowedPerQ = this.getAvg(pointsAllowedPerQ, avgPointsAllowedPerQ, year, 'q1');
      avgPointsAllowedPerQ = this.getAvg(pointsAllowedPerQ, avgPointsAllowedPerQ, year, 'q2');
      avgPointsAllowedPerQ = this.getAvg(pointsAllowedPerQ, avgPointsAllowedPerQ, year, 'q3');
      avgPointsAllowedPerQ = this.getAvg(pointsAllowedPerQ, avgPointsAllowedPerQ, year, 'q4');
    return { team: team, avgPointsScored: avgPoints, avgPointsAllowed: avgPointsAllowed, avgPointsPerQ: avgPointsPerQ, avgPointsAllowedPerQ: avgPointsAllowedPerQ, avgPlays};
  }

  getTeamPoints(team, obj) {
    let points = [];
    let pointsPerQ: Quarters = {q1: [], q2: [], q3: [], q4: []};
    let pointsAllowed = [];
    let pointsAllowedPerQ: Quarters = {q1: [], q2: [], q3: [], q4: []};
    let opponents = [];
    let plays = {total: [], off: [], def: []};
    for (let i = 0; i < obj.length; i++) {
      if (obj[i].homeTeam === team && obj[i].homePoints !== null) {
        points.push(obj[i].homePoints);
        pointsPerQ.q1.push(obj[i].homeLineScores[0]);
        pointsPerQ.q2.push(obj[i].homeLineScores[1]);
        pointsPerQ.q3.push(obj[i].homeLineScores[2]);
        pointsPerQ.q4.push(obj[i].homeLineScores[3]);
        pointsAllowed.push(obj[i].awayPoints);
        pointsAllowedPerQ.q1.push(obj[i].awayLineScores[0]);
        pointsAllowedPerQ.q2.push(obj[i].awayLineScores[1]);
        pointsAllowedPerQ.q3.push(obj[i].awayLineScores[2]);
        pointsAllowedPerQ.q4.push(obj[i].awayLineScores[3]);
        opponents.push({team: obj[i].awayTeam});
      } else if (obj[i].awayTeam === team && (obj[i].awayPoints !== null)) {
        points.push(obj[i].awayPoints);
        pointsPerQ.q1.push(obj[i].awayLineScores[0]);
        pointsPerQ.q2.push(obj[i].awayLineScores[1]);
        pointsPerQ.q3.push(obj[i].awayLineScores[2]);
        pointsPerQ.q4.push(obj[i].awayLineScores[3]);
        pointsAllowed.push(obj[i].homePoints);
        pointsAllowedPerQ.q1.push(obj[i].homeLineScores[0]);
        pointsAllowedPerQ.q2.push(obj[i].homeLineScores[1]);
        pointsAllowedPerQ.q3.push(obj[i].homeLineScores[2]);
        pointsAllowedPerQ.q4.push(obj[i].homeLineScores[3]);
        opponents.push({team: obj[i].homeTeam});
      }
      const teamOff = obj[i]?.playsByTeam?.find(i => i?.team === team);
      const teamDef = obj[i]?.playsByTeam?.find(i => i?.team !== team);
      (teamOff?.plays) ? plays.off.push(teamOff.plays) : null;
      (teamDef?.plays) ? plays.def.push(teamDef.plays) : null;
      (obj[i]?.totalPlays) ? plays.total.push(obj[i].totalPlays) : null;
    }
    return {points, pointsPerQ, pointsAllowed, pointsAllowedPerQ, opponents, plays};
  }

  getAvg(points, returnObj, year, subquery = null) {
    let avgs = returnObj;
    for (let i=year; i>=(year-1); i--) {
      if (points[i]?.length || (subquery && points[i][subquery]?.length)) {
        let total = 0;
        if (subquery) {
          for (let j = 0; j < points[i][subquery]?.length; j++) {
            total += points[i][subquery][j];
            avgs[i][subquery] = total;
          }
          avgs[i][subquery] = (total === 0 || !total) ? 0 : total/points[i][subquery].length;
          // avgs[i][subquery] = total/points[i][subquery].length;
        } else {
          for (let j = 0; j < points[i]?.length; j++) {
            total += points[i][j];
            avgs[i] = total;
          }
          avgs[i] = (total === 0 || !total) ? 0 : total/points[i].length;
          // avgs[i] = total/points[i].length;
        }
      }
    }
    // console.log(avgs);
    return avgs;
  }

  getWeightedAvg(obj, year, subquery = null) {
    let total = 0;
    let count = 0;
    let multiplier = 1;
    if (subquery) {
      for (let i=year; i>=(year-1); i--) {
        if (obj[i][subquery]) {
          multiplier = (i===year) ? this.curYearMult : (i===(year-1)) ? this.prvYearMult : this.multiplier;
          total += (obj[i][subquery]*multiplier);
          count++;
        }
      }
    } else {
      for (let i=year; i>=(year-1); i--) {
        if (obj[i]) {
          multiplier = (i===year) ? this.curYearMult : (i===(year-1)) ? this.prvYearMult : this.multiplier;
          total += (obj[i]*multiplier);
          count++;
        }
      }
    }

    if (subquery === 'def') {
      // console.log(obj);
    }
    return (total !== 0 || total) ? (count>0) ? total/count : 0 : 0;
  }

  getOpponentsWeightedAvg(obj, field, year, subquery = null) {
    let total = 0;
    for (let t=0; t < obj.length; t++) {
      let subTotal = 0;
      let count = 0;
      let oppTotal = 0;
      let multiplier = 1;
      if (subquery) {
        for (let i=year; i>=(year-1); i--) {
          if (obj[t][field][i][subquery]) {
            multiplier = (i===year) ? this.curYearMult : (i===(year-1)) ? this.prvYearMult : this.multiplier;
            subTotal += (obj[t][field][i][subquery]*multiplier);
            count++;
          }
        }
        oppTotal += (subTotal !== 0) ? (count>0) ? subTotal/count : null : 0;
      } else {
        for (let i=year; i>=(year-1); i--) {
          if (obj[t][field][i]) {
            multiplier = (i===year) ? this.curYearMult : (i===(year-1)) ? this.prvYearMult : this.multiplier;
            subTotal += (obj[t][field][i]*multiplier);
            count++;
          }
        }
        oppTotal += (subTotal !== 0 || subTotal) ? (count>0) ? subTotal/count : 0 : 0;
      }
      total += oppTotal;
    }
    return (obj.length !== 0) ? total/obj.length : 0;
  }
}
