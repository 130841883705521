import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { QuartersAvg } from '../domain/quarters';
import { TeamInfo } from '../domain/team-info';
import { TeamsPlayed } from '../domain/teams-played';
import { CalcService } from '../services/calc.service';
import { CFBDataService } from '../services/cfb-data.service';

@Component({
  selector: 'cfbw-matchup-prediction',
  templateUrl: './matchup-prediction.component.html',
  styleUrls: ['./matchup-prediction.component.scss']
})
export class MatchupPredictionComponent implements OnInit, OnChanges {

  @Input() currentWeek: number = null;
  @Input() isNeutral: boolean = null;
  @Input() teamInfo: { home: string, away: string } = null;

  @Output() loading = new EventEmitter<boolean>(true);
  
  year = 2023;
  includeLastYear = true;
  phoneDevice: boolean;

  // AWAY TEAM
  aTeam: TeamInfo;
  aAvgPoints: number;
  aAvgPointsPerQ: QuartersAvg = {q1: 0, q2: 0, q3: 0, q4: 0};
  aAvgPointsAllowed: number;
  aAvgPointsAllowedPerQ: QuartersAvg = {q1: 0, q2: 0, q3: 0, q4: 0};
  aAvgPlays: {total: number, off: number, def: number} = {total: null, off: null, def: null};
  aOffPPA: number;
  aDefPPA: number;
  aTotalPPA: number;

  aOpponents: TeamsPlayed[];

  aAvgPtsScoredByOpponents: number;
  aAvgPtsByOpponentsPerQ: QuartersAvg = {q1: 0, q2: 0, q3: 0, q4: 0};
  aAvgPtsAllowedByOpponents: number;
  aAvgPtsAllowedByOpponentsPerQ: QuartersAvg = {q1: 0, q2: 0, q3: 0, q4: 0};
  aAvgPlaysByOpponent: {total: number, off: number, def: number} = {total: null, off: null, def: null};

  aOffPlaysPct: number;
  aDefPlaysPct: number;
  aPlaysPerfFigure: number;

  aOffensivePct: number;
  aDefensivePct: number;
  aPerformanceFigure: number;

  // HOME TEAM
  hTeam: TeamInfo;
  hAvgPoints: number;
  hAvgPointsPerQ: QuartersAvg = {q1: 0, q2: 0, q3: 0, q4: 0};
  hAvgPointsAllowed: number;
  hAvgPointsAllowedPerQ: QuartersAvg = {q1: 0, q2: 0, q3: 0, q4: 0};
  hAvgPlays: {total: number, off: number, def: number} = {total: null, off: null, def: null};
  hOffPPA: number;
  hDefPPA: number;
  hTotalPPA: number;

  hOpponents: TeamsPlayed[];

  hAvgPtsScoredByOpponents: number;
  hAvgPtsByOpponentsPerQ: QuartersAvg = {q1: 0, q2: 0, q3: 0, q4: 0};
  hAvgPtsAllowedByOpponents: number;
  hAvgPtsAllowedByOpponentsPerQ: QuartersAvg = {q1: 0, q2: 0, q3: 0, q4: 0};
  hAvgPlaysByOpponent: {total: number, off: number, def: number} = {total: null, off: null, def: null};

  hOffPlaysPct: number;
  hDefPlaysPct: number;
  hPlaysPerfFigure: number;

  hOffensivePct: number;
  hDefensivePct: number;
  hPerformanceFigure: number;

  // Predictions
  estimatedPlays: {total: number, off: number, def: number} = {total: null, off: null, def: null};
  aPredictedScoreByPlays: number;
  hPredictedScoreByPlays: number;
  aPredictedScoreByPoints: number;
  hPredictedScoreByPoints: number;
  homeTeamSpread;
  homeSpread: number;
  overUnder: number;
  aBaseScore: number;
  aBaseScoreQ: QuartersAvg = {q1: 0, q2: 0, q3: 0, q4: 0};
  hBaseScore: number;
  hBaseScoreQ: QuartersAvg = {q1: 0, q2: 0, q3: 0, q4: 0};
  weightedScore: {
    a: { q1: number, q2: number, q3: number, q4: number, total: number }, 
    h: { q1: number, q2: number, q3: number, q4: number, total: number }
  };

  collectionDone = { home: false, away: false };

  constructor(private cfb: CFBDataService, private calc: CalcService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    // if (!changes.teamInfo.firstChange) {
      if (changes.currentWeek && changes.currentWeek.currentValue >= 4) {
        this.includeLastYear = false;
      } 
      if (changes.teamInfo.currentValue.home && changes.teamInfo.currentValue.away) {
        this.loading.emit(true);
        this.collectData(changes.teamInfo.currentValue);
      }
    // }
  }

  async collectData(teams) {
    if (teams.home && teams.away) {
      console.log(teams);
      await this.cfb.getTeamInfo(teams.away).then(team => {
        // console.log(team);
        this.aTeam = team;
      });
      
      await this.cfb.getTeamInfo(teams.home).then(team => {
        this.hTeam = team;
      });
      await this.getGameResults(this.aTeam, false).then(() => this.collectionDone.away = true);
      await this.getGameResults(this.hTeam, true).then(() => this.collectionDone.home = true);
      await this.calcPerfAndPrediction();
    }
    
  }

  async getGameResults(team, isHome?) {
    this.cfb.getGameResults(team.school, this.year).then(
      async obj => {
        obj[this.year].forEach(game => {
          console.log(`${this.year}: ${game.awayTeam} @ ${game.homeTeam}`, game);
        });
        obj[this.year-1].forEach(game => {
          console.log(`${this.year}: ${game.awayTeam} @ ${game.homeTeam}`, game);
        });
        const returnData = await this.calc.calcTeamPoints(team.school, this.year, obj);
        if (!isHome) {
          this.aAvgPoints = await this.calc.getWeightedAvg(returnData.avgPointsScored, this.year);
          this.aAvgPointsPerQ.q1 = await this.calc.getWeightedAvg(returnData.avgPointsPerQ, this.year, 'q1');
          this.aAvgPointsPerQ.q2 = await this.calc.getWeightedAvg(returnData.avgPointsPerQ, this.year, 'q2');
          this.aAvgPointsPerQ.q3 = await this.calc.getWeightedAvg(returnData.avgPointsPerQ, this.year, 'q3');
          this.aAvgPointsPerQ.q4 = await this.calc.getWeightedAvg(returnData.avgPointsPerQ, this.year, 'q4');
          this.aAvgPointsAllowed = await this.calc.getWeightedAvg(returnData.avgPointsAllowed, this.year);
          this.aAvgPointsAllowedPerQ.q1 = await this.calc.getWeightedAvg(returnData.avgPointsAllowedPerQ, this.year, 'q1');
          this.aAvgPointsAllowedPerQ.q2 = await this.calc.getWeightedAvg(returnData.avgPointsAllowedPerQ, this.year, 'q2');
          this.aAvgPointsAllowedPerQ.q3 = await this.calc.getWeightedAvg(returnData.avgPointsAllowedPerQ, this.year, 'q3');
          this.aAvgPointsAllowedPerQ.q4 = await this.calc.getWeightedAvg(returnData.avgPointsAllowedPerQ, this.year, 'q4');
  
          this.aAvgPlays.total = await this.calc.getWeightedAvg(returnData.avgPlays, this.year, 'total');
          this.aAvgPlays.off = await this.calc.getWeightedAvg(returnData.avgPlays, this.year, 'off');
          this.aAvgPlays.def = await this.calc.getWeightedAvg(returnData.avgPlays, this.year, 'def');
          this.aOpponents = returnData.opponents;
          await this.getAtOpponentsData(0);
        } else {
          this.hAvgPoints = await this.calc.getWeightedAvg(returnData.avgPointsScored, this.year);
          this.hAvgPointsPerQ.q1 = await this.calc.getWeightedAvg(returnData.avgPointsPerQ, this.year, 'q1');
          this.hAvgPointsPerQ.q2 = await this.calc.getWeightedAvg(returnData.avgPointsPerQ, this.year, 'q2');
          this.hAvgPointsPerQ.q3 = await this.calc.getWeightedAvg(returnData.avgPointsPerQ, this.year, 'q3');
          this.hAvgPointsPerQ.q4 = await this.calc.getWeightedAvg(returnData.avgPointsPerQ, this.year, 'q4');
          
          this.hAvgPointsAllowed = await this.calc.getWeightedAvg(returnData.avgPointsAllowed, this.year);
          this.hAvgPointsAllowedPerQ.q1 = await this.calc.getWeightedAvg(returnData.avgPointsAllowedPerQ, this.year, 'q1');
          this.hAvgPointsAllowedPerQ.q2 = await this.calc.getWeightedAvg(returnData.avgPointsAllowedPerQ, this.year, 'q2');
          this.hAvgPointsAllowedPerQ.q3 = await this.calc.getWeightedAvg(returnData.avgPointsAllowedPerQ, this.year, 'q3');
          this.hAvgPointsAllowedPerQ.q4 = await this.calc.getWeightedAvg(returnData.avgPointsAllowedPerQ, this.year, 'q4');
  
          this.hAvgPlays.total = await this.calc.getWeightedAvg(returnData.avgPlays, this.year, 'total');
          this.hAvgPlays.off = await this.calc.getWeightedAvg(returnData.avgPlays, this.year, 'off');
          this.hAvgPlays.def = await this.calc.getWeightedAvg(returnData.avgPlays, this.year, 'def');

          this.hOpponents = returnData.opponents;
          await this.getHtOpponentsData(0);
        }
      }
    )
    return true;
  }

  async getAtOpponentsData(index) {
    const team = this.aOpponents[index]?.team;
    const lastIndex = this.aOpponents.length - 1;
    if (index <= lastIndex) {
      await this.cfb.getGameResults(team, this.year).then(
        obj => {
          this.aOpponents[index] = this.calc.calcOpponentsPoints(team, this.year, obj);
          this.getAtOpponentsData(index + 1);
        }
      )
    } else {
        this.aAvgPtsScoredByOpponents = await this.calc.getOpponentsWeightedAvg(this.aOpponents, 'avgPointsScored', this.year);
        this.aAvgPtsByOpponentsPerQ.q1 = await this.calc.getOpponentsWeightedAvg(this.aOpponents, 'avgPointsPerQ', this.year, 'q1');
        this.aAvgPtsByOpponentsPerQ.q2 = await this.calc.getOpponentsWeightedAvg(this.aOpponents, 'avgPointsPerQ', this.year, 'q2');
        this.aAvgPtsByOpponentsPerQ.q3 = await this.calc.getOpponentsWeightedAvg(this.aOpponents, 'avgPointsPerQ', this.year, 'q3');
        this.aAvgPtsByOpponentsPerQ.q4 = await this.calc.getOpponentsWeightedAvg(this.aOpponents, 'avgPointsPerQ', this.year, 'q4');
        
        this.aAvgPtsAllowedByOpponents = await this.calc.getOpponentsWeightedAvg(this.aOpponents, 'avgPointsAllowed', this.year);
        this.aAvgPtsAllowedByOpponentsPerQ.q1 = await this.calc.getOpponentsWeightedAvg(this.aOpponents, 'avgPointsAllowedPerQ', this.year, 'q1');
        this.aAvgPtsAllowedByOpponentsPerQ.q2 = await this.calc.getOpponentsWeightedAvg(this.aOpponents, 'avgPointsAllowedPerQ', this.year, 'q2');
        this.aAvgPtsAllowedByOpponentsPerQ.q3 = await this.calc.getOpponentsWeightedAvg(this.aOpponents, 'avgPointsAllowedPerQ', this.year, 'q3');
        this.aAvgPtsAllowedByOpponentsPerQ.q4 = await this.calc.getOpponentsWeightedAvg(this.aOpponents, 'avgPointsAllowedPerQ', this.year, 'q4');

        this.aAvgPlaysByOpponent.total = await this.calc.getOpponentsWeightedAvg(this.aOpponents, 'avgPlays', this.year, 'total');
        this.aAvgPlaysByOpponent.off = await this.calc.getOpponentsWeightedAvg(this.aOpponents, 'avgPlays', this.year, 'off');
        this.aAvgPlaysByOpponent.def = await this.calc.getOpponentsWeightedAvg(this.aOpponents, 'avgPlays', this.year, 'def');
    }
  }

  async getHtOpponentsData(index) {
    const team = this.hOpponents[index]?.team;
    const lastIndex = this.hOpponents.length - 1;
    // console.log({'lastindex':lastIndex});
    if (index <= lastIndex) {
      await this.cfb.getGameResults(team, this.year).then(
        obj => {
          this.hOpponents[index] = this.calc.calcOpponentsPoints(team, this.year, obj);
          this.getHtOpponentsData(index + 1);
        }
      )
    } else {
      this.hAvgPtsScoredByOpponents = await this.calc.getOpponentsWeightedAvg(this.hOpponents, 'avgPointsScored', this.year);
      this.hAvgPtsByOpponentsPerQ.q1 = await this.calc.getOpponentsWeightedAvg(this.hOpponents, 'avgPointsPerQ', this.year, 'q1');
      this.hAvgPtsByOpponentsPerQ.q2 = await this.calc.getOpponentsWeightedAvg(this.hOpponents, 'avgPointsPerQ', this.year, 'q2');
      this.hAvgPtsByOpponentsPerQ.q3 = await this.calc.getOpponentsWeightedAvg(this.hOpponents, 'avgPointsPerQ', this.year, 'q3');
      this.hAvgPtsByOpponentsPerQ.q4 = await this.calc.getOpponentsWeightedAvg(this.hOpponents, 'avgPointsPerQ', this.year, 'q4');
      
      this.hAvgPtsAllowedByOpponents = await this.calc.getOpponentsWeightedAvg(this.hOpponents, 'avgPointsAllowed', this.year);
      this.hAvgPtsAllowedByOpponentsPerQ.q1 = await this.calc.getOpponentsWeightedAvg(this.hOpponents, 'avgPointsAllowedPerQ', this.year, 'q1');
      this.hAvgPtsAllowedByOpponentsPerQ.q2 = await this.calc.getOpponentsWeightedAvg(this.hOpponents, 'avgPointsAllowedPerQ', this.year, 'q2');
      this.hAvgPtsAllowedByOpponentsPerQ.q3 = await this.calc.getOpponentsWeightedAvg(this.hOpponents, 'avgPointsAllowedPerQ', this.year, 'q3');
      this.hAvgPtsAllowedByOpponentsPerQ.q4 = await this.calc.getOpponentsWeightedAvg(this.hOpponents, 'avgPointsAllowedPerQ', this.year, 'q4');

      this.hAvgPlaysByOpponent.total = await this.calc.getOpponentsWeightedAvg(this.hOpponents, 'avgPlays', this.year, 'total');
      this.hAvgPlaysByOpponent.off = await this.calc.getOpponentsWeightedAvg(this.hOpponents, 'avgPlays', this.year, 'off');
      this.hAvgPlaysByOpponent.def = await this.calc.getOpponentsWeightedAvg(this.hOpponents, 'avgPlays', this.year, 'def');
    }
    // console.log(this.hOpponents);
  }

  async calcPerfAndPrediction() {
    const appa1 = this.aTeam.ppa.find(i => i.year === this.year);
    const hppa1 = this.hTeam.ppa.find(i => i.year === this.year);

    const appa2 = this.aTeam.ppa.find(i => i.year === this.year-1);
    const hppa2 = this.hTeam.ppa.find(i => i.year === this.year-1);
    console.log(this.aTeam.school, appa1, appa2);
    console.log(this.hTeam.school, hppa1, hppa2);
    // this.aOffPPA = (appa1?.offPPA && hppa1?.offPPA) ? ((appa1.offPPA)+(appa2.offPPA)/2) : appa2.offPPA;
    // this.aDefPPA = (appa1?.defPPA && hppa1?.defPPA) ? ((appa1.defPPA)+(appa2.defPPA)/2) : appa2.defPPA;
    // this.hOffPPA = (hppa1?.offPPA && appa1?.offPPA) ? ((hppa1.offPPA)+(hppa2.offPPA)/2) : hppa2.offPPA;
    // this.hDefPPA = (hppa1?.defPPA && appa1?.defPPA) ? ((hppa1.defPPA)+(hppa2.defPPA)/2) : hppa2.defPPA;
    this.aOffPPA = (this.includeLastYear) ? ((appa1.offPPA)+(appa2.offPPA))/2 : appa1.offPPA;
    this.aDefPPA = (this.includeLastYear) ? ((appa1.defPPA)+(appa2.defPPA))/2 : appa1.defPPA;
    this.hOffPPA = (this.includeLastYear) ? ((hppa1.offPPA)+(hppa2.offPPA))/2 : hppa1.offPPA;
    this.hDefPPA = (this.includeLastYear) ? ((hppa1.defPPA)+(hppa2.defPPA))/2 : hppa1.defPPA;

    if (this.aAvgPtsAllowedByOpponents && this.aAvgPtsScoredByOpponents) {
      this.estimatedPlays.total = (this.aAvgPlays.total+this.hAvgPlays.total)/2;
      this.estimatedPlays.off = (this.aAvgPlays.off+this.hAvgPlays.def)/2;
      this.estimatedPlays.def = (this.aAvgPlays.def+this.hAvgPlays.off)/2;

      const adefppa = (this.aDefPPA < 0) ? +this.aDefPPA : 0;
      const aPPA = (+this.aOffPPA + +this.hDefPPA) - adefppa;
      const hdefppa = (this.hDefPPA < 0) ? +this.hDefPPA : 0;
      const hPPA = (+this.hOffPPA + +this.aDefPPA) - hdefppa;
      
      this.aOffensivePct = this.aAvgPoints / this.aAvgPtsAllowedByOpponents;
      this.aDefensivePct = this.aAvgPointsAllowed / this.aAvgPtsScoredByOpponents;
      this.hOffensivePct = this.hAvgPoints / this.hAvgPtsAllowedByOpponents;
      this.hDefensivePct = this.hAvgPointsAllowed / this.hAvgPtsScoredByOpponents;

      this.aPerformanceFigure = (this.aOffensivePct + this.hDefensivePct) / 2;
      this.hPerformanceFigure = (this.hOffensivePct + this.aDefensivePct) / 2;

      this.aOffPlaysPct = (this.aAvgPlaysByOpponent.def !== 0) ? (this.aAvgPlays.off/this.aAvgPlaysByOpponent.def) : 1;
      this.aDefPlaysPct = (this.aAvgPlaysByOpponent.off !== 0) ? (this.aAvgPlays.def/this.aAvgPlaysByOpponent.off) : 1;
      this.hOffPlaysPct = (this.hAvgPlaysByOpponent.def !== 0) ? (this.hAvgPlays.off/this.hAvgPlaysByOpponent.def) : 1;
      this.hDefPlaysPct = (this.hAvgPlaysByOpponent.off !== 0) ? (this.hAvgPlays.def/this.hAvgPlaysByOpponent.off) : 1;

      this.aPlaysPerfFigure = (this.aOffPlaysPct + this.hDefPlaysPct) / 2;
      this.hPlaysPerfFigure = (this.hOffPlaysPct + this.aDefPlaysPct) / 2;

      const aPlays = (this.estimatedPlays.off*this.aPlaysPerfFigure);
      const hPlays = (this.estimatedPlays.def*this.hPlaysPerfFigure);

      const aPointsPlayPerf2 = (((this.aAvgPoints / this.aAvgPlays.off)) + ((this.hAvgPointsAllowed / this.hAvgPlays.def)))/2;
      const hPointsPlayPerf2 = (((this.hAvgPoints / this.hAvgPlays.off)) + ((this.aAvgPointsAllowed / this.aAvgPlays.def)))/2;

      const aPointsScore1 = ((aPlays+hPlays)/2)*(aPPA);
      const hPointsScore1 = ((aPlays+hPlays)/2)*(hPPA);
      const aPointsScore2 = ((aPlays+hPlays)/2)*(aPointsPlayPerf2);
      const hPointsScore2 = ((aPlays+hPlays)/2)*(hPointsPlayPerf2);
      this.aPredictedScoreByPlays = (aPointsScore1 + aPointsScore2)/2;
      this.hPredictedScoreByPlays = (hPointsScore1 + hPointsScore2)/2;

      this.aBaseScore = (this.aAvgPoints + this.hAvgPointsAllowed) / 2;
      this.aBaseScoreQ = {
        q1: (this.aAvgPointsPerQ.q1 + this.hAvgPointsAllowedPerQ.q1) / 2,
        q2: (this.aAvgPointsPerQ.q2 + this.hAvgPointsAllowedPerQ.q2) / 2,
        q3: (this.aAvgPointsPerQ.q3 + this.hAvgPointsAllowedPerQ.q3) / 2,
        q4: (this.aAvgPointsPerQ.q4 + this.hAvgPointsAllowedPerQ.q4) / 2
      };

      this.hBaseScore = (this.hAvgPoints + this.aAvgPointsAllowed) / 2;
      this.hBaseScoreQ = {
        q1: (this.hAvgPointsPerQ.q1 + this.aAvgPointsAllowedPerQ.q1) / 2,
        q2: (this.hAvgPointsPerQ.q2 + this.aAvgPointsAllowedPerQ.q2) / 2,
        q3: (this.hAvgPointsPerQ.q3 + this.aAvgPointsAllowedPerQ.q3) / 2,
        q4: (this.hAvgPointsPerQ.q4 + this.aAvgPointsAllowedPerQ.q4) / 2
      };

      const aQPerc = {
        q1: this.aBaseScoreQ.q1 / this.aBaseScore, q2: this.aBaseScoreQ.q2 / this.aBaseScore,
        q3: this.aBaseScoreQ.q3 / this.aBaseScore, q4: this.aBaseScoreQ.q4 / this.aBaseScore,
      }
      const hQPerc = {
        q1: this.hBaseScoreQ.q1 / this.hBaseScore, q2: this.hBaseScoreQ.q2 / this.hBaseScore,
        q3: this.hBaseScoreQ.q3 / this.hBaseScore, q4: this.hBaseScoreQ.q4 / this.hBaseScore,
      }

      this.aPredictedScoreByPoints = (this.aBaseScore*this.aPerformanceFigure);
      this.hPredictedScoreByPoints = (this.hBaseScore*this.hPerformanceFigure);

      this.weightedScore = {
        a: {
          q1: ((this.aBaseScoreQ.q1*this.aPerformanceFigure)+(aQPerc.q1*this.aPredictedScoreByPlays))/2,
          q2: ((this.aBaseScoreQ.q2*this.aPerformanceFigure)+(aQPerc.q2*this.aPredictedScoreByPlays))/2,
          q3: ((this.aBaseScoreQ.q3*this.aPerformanceFigure)+(aQPerc.q3*this.aPredictedScoreByPlays))/2,
          q4: ((this.aBaseScoreQ.q4*this.aPerformanceFigure)+(aQPerc.q4*this.aPredictedScoreByPlays))/2,
          total: ((this.aPredictedScoreByPoints+this.aPredictedScoreByPlays)/2)
        },
        h: {
          q1: ((this.hBaseScoreQ.q1*this.hPerformanceFigure)+(hQPerc.q1*this.hPredictedScoreByPlays))/2,
          q2: ((this.hBaseScoreQ.q2*this.hPerformanceFigure)+(hQPerc.q2*this.hPredictedScoreByPlays))/2,
          q3: ((this.hBaseScoreQ.q3*this.hPerformanceFigure)+(hQPerc.q3*this.hPredictedScoreByPlays))/2,
          q4: ((this.hBaseScoreQ.q4*this.hPerformanceFigure)+(hQPerc.q4*this.hPredictedScoreByPlays))/2,
          total: ((this.hPredictedScoreByPoints+this.hPredictedScoreByPlays)/2)
        }
      };


      if (!this.isNeutral) {
        this.weightedScore.a.q1 -= 0.375;
        this.weightedScore.a.q2 -= 0.375;
        this.weightedScore.a.q3 -= 0.375;
        this.weightedScore.a.q4 -= 0.375;
        this.weightedScore.a.total -= 1.5;

        this.weightedScore.h.q1 += 0.375;
        this.weightedScore.h.q2 += 0.375;
        this.weightedScore.h.q3 += 0.375;
        this.weightedScore.h.q4 += 0.375;
        this.weightedScore.h.total += 1.5;
      }

      this.overUnder = this.weightedScore.a.total + this.weightedScore.h.total;
      this.homeTeamSpread = (this.weightedScore.a.total > this.weightedScore.h.total) ? this.aTeam.abbreviation : this.hTeam.abbreviation;
      this.homeSpread = (this.weightedScore.a.total > this.weightedScore.h.total) ? this.weightedScore.h.total - this.weightedScore.a.total : this.weightedScore.a.total - this.weightedScore.h.total;

      this.loading.emit(false);

      const aPlaysPerf = ((this.aAvgPlays.off / this.aAvgPlaysByOpponent.def) + (this.hAvgPlays.def / this.hAvgPlaysByOpponent.off))/2;
      const hPlaysPerf = ((this.hAvgPlays.off / this.hAvgPlaysByOpponent.def) + (this.aAvgPlays.def / this.aAvgPlaysByOpponent.off))/2;

      const aPointsPlayPerf = (((this.aAvgPoints / this.aAvgPlays.off) / (this.aAvgPtsAllowedByOpponents/this.aAvgPlaysByOpponent.def)) + ((this.hAvgPointsAllowed / this.hAvgPlays.def) / (this.hAvgPtsScoredByOpponents/this.hAvgPlaysByOpponent.off)))/2;
      const hPointsPlayPerf = (((this.hAvgPoints / this.hAvgPlays.off) / (this.hAvgPtsAllowedByOpponents/this.hAvgPlaysByOpponent.def)) + ((this.aAvgPointsAllowed / this.aAvgPlays.def) / (this.aAvgPtsScoredByOpponents/this.aAvgPlaysByOpponent.off)))/2;
      


      console.log(`${this.aTeam.school} @ ${this.hTeam.school}`);
      console.log(`${this.aTeam.school} | points: ${this.aAvgPoints}, points allowed: ${this.aAvgPointsAllowed}`);
      console.log(`${this.aTeam.school} | plays: ${this.aAvgPlays.off}, plays allowed: ${this.aAvgPlays.def}`);
      console.log(`${this.aTeam.school} | plays perf: ${this.aOffPlaysPct}, plays allowed perf: ${this.aDefPlaysPct}`);
      console.log(`${this.aTeam.school} | plays perf: ${aPlaysPerf}`);
      console.log(`${this.aTeam.school} | points/play: ${this.aAvgPoints / this.aAvgPlays.off}, points/play allowed: ${this.aAvgPointsAllowed / this.aAvgPlays.def}`);
      console.log(`${this.aTeam.school} | points/play perf: ${(this.aAvgPoints / this.aAvgPlays.off) / (this.aAvgPtsAllowedByOpponents/this.aAvgPlaysByOpponent.def)}, points/play allowed perf: ${(this.aAvgPointsAllowed / this.aAvgPlays.def) / (this.aAvgPtsScoredByOpponents/this.aAvgPlaysByOpponent.off)}`);
      console.log(`${this.aTeam.school} | points/play perf: ${aPointsPlayPerf}`);
      console.log(`${this.aTeam.school} | points/play perf: ${aPointsPlayPerf2}`);
      // console.log(`${this.aTeam.school} | points/play perf: ${(this.aAvgPoints / this.aAvgPlays.off) / (this.aAvgPtsAllowedByOpponents/this.aAvgPlaysByOpponent.def)}, points/play allowed perf: ${(this.aAvgPointsAllowed / this.aAvgPlays.def) / (this.aAvgPtsScoredByOpponents/this.aAvgPlaysByOpponent.off)}`);
      console.log(`${this.hTeam.school} | points: ${this.hAvgPoints}, points allowed: ${this.hAvgPointsAllowed}`);
      console.log(`${this.hTeam.school} | plays: ${this.hAvgPlays.off}, plays allowed: ${this.hAvgPlays.def}`);
      console.log(`${this.hTeam.school} | plays perf: ${this.hOffPlaysPct}, plays allowed: ${this.hDefPlaysPct}`);
      console.log(`${this.hTeam.school} | plays perf: ${hPlaysPerf}`);
      console.log(`${this.hTeam.school} | points/plays: ${this.hAvgPoints / this.hAvgPlays.off}, points/plays allowed: ${this.hAvgPointsAllowed / this.hAvgPlays.def}`);
      console.log(`${this.hTeam.school} | points/play perf: ${(this.hAvgPoints / this.hAvgPlays.off) / (this.hAvgPtsAllowedByOpponents/this.hAvgPlaysByOpponent.def)}, points/play allowed perf: ${(this.hAvgPointsAllowed / this.hAvgPlays.def) / (this.hAvgPtsScoredByOpponents/this.hAvgPlaysByOpponent.off)}`);
      console.log(`${this.hTeam.school} | points/play perf: ${hPointsPlayPerf}`);
      console.log(`${this.hTeam.school} | points/play perf: ${hPointsPlayPerf2}`);
      // console.log(`${this.hTeam.school} | points/play perf: ${(this.hAvgPoints / this.hAvgPlays.off) / (this.hAvgPtsAllowedByOpponents/this.hAvgPlaysByOpponent.def)}, points/play allowed perf: ${(this.hAvgPointsAllowed / this.hAvgPlays.def) / (this.hAvgPtsScoredByOpponents/this.hAvgPlaysByOpponent.off)}`);
      console.log(`BaseScore | ${this.aBaseScore} - ${this.hBaseScore}`);
      console.log(`avgPlays | ${(aPlays+hPlays)/2}`);
      console.log(`totalPlays | ${(aPlays+hPlays)/2}`);
      console.log(`estimated plays | ${this.estimatedPlays.off} - ${this.estimatedPlays.def}`);
      console.log(`plays perf figure | ${this.aPlaysPerfFigure} - ${this.hPlaysPerfFigure}`);
      console.log(`totalPlays | ${aPlays} - ${hPlays}`);
      console.log(`ppa | ${aPPA} - ${hPPA}`);
      console.log(`NEW PREDICTION BY POINTS PER PLAY 1 | ${(aPlays+hPlays)*(aPPA)} - ${(aPlays+hPlays)*(hPPA)}`);
      console.log(`NEW PREDICTION BY POINTS PER PLAY 2 | ${((aPlays+hPlays)/2)*(aPointsPlayPerf2)} - ${((aPlays+hPlays)/2)*(hPointsPlayPerf2)}`);
      console.log(`NEW PREDICTION BY POINTS PER PLAY t | ${((((aPlays+hPlays)*(aPPA)) + (((aPlays+hPlays)/2)*(aPointsPlayPerf2)))/2)} - ${((((aPlays+hPlays)*(hPPA)) + (((aPlays+hPlays)/2)*(hPointsPlayPerf2)))/2)}`);
      console.log(`points scored per play | ${(this.aAvgPoints/this.aAvgPlays.off) + (this.hAvgPointsAllowed/this.hAvgPlays.def)} - ${(this.hAvgPoints/this.hAvgPlays.off) + (this.aAvgPointsAllowed/this.aAvgPlays.def)}`);
      console.log(`1st half playsScore | ${((aPlays+hPlays)/2)*(((this.aAvgPoints/this.aAvgPlays.off)+(this.hAvgPointsAllowed/this.hAvgPlays.def))/2)-1.5} - ${((aPlays+hPlays)/2)*(((this.hAvgPoints/this.hAvgPlays.off)+(this.aAvgPointsAllowed/this.aAvgPlays.def))/2)+1.5}`);
      console.log(`2nd half playsScore | ${((aPlays+hPlays)/2)*(aPPA)} - ${((aPlays+hPlays)/2)*(hPPA)}`);
      console.log(`potential add playsScore | ${(aPlays)*(((this.aAvgPoints/this.aAvgPlays.off)+(this.hAvgPointsAllowed/this.hAvgPlays.def))/2)-1.5} - ${(hPlays)*(((this.hAvgPoints/this.hAvgPlays.off)+(this.aAvgPointsAllowed/this.aAvgPlays.def))/2)+1.5}`);
      console.log(`PlaysScore | ${this.aPredictedScoreByPlays-1.5} - ${this.hPredictedScoreByPlays+1.5}`);
      console.log(`PointsScore| ${this.aPredictedScoreByPoints-1.5}-${this.hPredictedScoreByPoints+1.5}`);
      console.log(`PredictedScore | ${this.weightedScore.a.total}-${this.weightedScore.h.total}`);
      // }
    } else {
      setTimeout(() => { this.calcPerfAndPrediction(); }, 5000);
    }
  }
}
